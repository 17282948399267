import {Loader, LoaderSizes} from "@design-system/ui-kit";
import { Trans, useTranslation } from "react-i18next";

export const PendingPayout = ({isActivateAuto}: {isActivateAuto: boolean}) => {
    const {t} = useTranslation()

    if (isActivateAuto)
        return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
            <div className={'w-20 aspect-square text-brand-primary'}>
                <Loader size={LoaderSizes.l}/>
            </div>
            <div className={'text-center text-header-03 font-heading font-extrabold max-w-[22.5rem]'}>
                {t('Activating Auto Instant Payouts')}
            </div>
            <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
            <Trans i18nKey="payoutProcessInstructions">
                Please wait, the payout process usually happens within 2 min, but sometimes it may take up to 60 min.
                <br />
                <br />
                You will find the final amount in the Wallet after we process the payout.
            </Trans>
            </div>
        </div>

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}>
            <Loader size={LoaderSizes.l}/>
        </div>
        <div className={'text-center text-header-03 font-heading font-extrabold max-w-[22.5rem]'}>
            {t('Making Instant Payout')}
        </div>
        <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
        <Trans i18nKey="payoutProcessInstructions">
            Please wait, the payout process usually happens within 2 min, but sometimes it may take up to 60 min.<br />
            <br />
            You will find the final amount in the Wallet after we process the payout.
        </Trans>
        </div>
    </div>
}
