import {useMutation} from "@tanstack/react-query";
import {saveQuestionnaire} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useCreateQuestionnaire = () => {
    const {
        data,
        mutateAsync,
        mutate,
        isError,
        isPending,
        isSuccess
    } = useMutation({
        mutationKey: [MutationKeys.CREATE_QUESTIONNAIRE],
        mutationFn: (data: Parameters<typeof saveQuestionnaire>[0]) => {
            return saveQuestionnaire(data);
        }
    })

    return {
        data,
        mutateAsync,
        mutate,
        isError,
        isPending,
        isSuccess
    }
}
