import {useMutation} from "@tanstack/react-query";
import {createPayout} from "@capyfast/api";
import {AdvancedCalculationSetSchema, PayoutTypes} from "@capyfast/contracts";
import {useRecoilState} from "recoil";

import {activeAdvanceCalculationSetAtom} from "../../../core/store";

export const useCreatePayout = () => {
    const [, setAdvanceCalculationSet] = useRecoilState(activeAdvanceCalculationSetAtom);
    const {
        mutate,
        isPending,
        isSuccess,
        isError,
        reset
    } = useMutation({
        mutationFn: async (type: PayoutTypes) => {
            try {
                const response = await createPayout(type);
                const data = AdvancedCalculationSetSchema.parse(response.data);
                setAdvanceCalculationSet(data);
            } catch (e) {
                setAdvanceCalculationSet(null);
                throw e;
            }
        }
    })


    const resetCreatePayout = () => {
        reset();
        setAdvanceCalculationSet(null);
    }

    return {
        create: mutate,
        isPending,
        isSuccess,
        isError,
        resetCreatePayout
    }
}
