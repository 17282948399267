import {useMutation} from "@tanstack/react-query";
import {createPassport} from "@capyfast/api";

type MutationProps = {
    type: Parameters<typeof createPassport>[0]
}

export const useCreatePassportMutation = () => {
    const {
        data,
        mutateAsync,
        isSuccess,
        isPending,
        isError
    } = useMutation({
        mutationKey: ['createPassport'],
        mutationFn: (props: MutationProps) => {
            return createPassport(props.type)
                .then(response => response.data)
                .catch(() => {
                    return Promise.reject(null);
                });
        }
    })

    return {
        passport: data,
        createPassport: mutateAsync,
        isSuccess,
        isPending,
        isError
    }
}
