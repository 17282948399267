import {ReactNode} from "react";
import classnames from "classnames";

type ServiceNotAvailableProps = {
    title?: ReactNode,
    description?: ReactNode,
    className?: string
}

export const ServiceNotAvailable = ({title, description, className}: ServiceNotAvailableProps) => {

    return <div className={classnames('flex flex-col gap-size-3 justify-center items-center', className)}>
        <div>
            <img src={'/images/capybara-service-not-available.svg'} alt={'Service not available'}/>
        </div>
        <div className={'text-center'}>
            <div className={'text-header-03 text-label-primary font-heading font-extrabold mb-5'}>
                {title ?? 'Sorry, the service is not available for you.'}
            </div>
            <div className={'text-label-tertiary text-body-01'}>
                {description ?? 'If something will change, we will contact you!'}
            </div>
        </div>

    </div>
}
