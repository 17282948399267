import {createContext, ReactNode, useContext} from "react";
import {UIKitContextProvider} from "@design-system/ui-kit";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {useTranslation} from "react-i18next";

import {useApiConfigurator} from "./api-configurator";

type AppProviderProps = {
    children: ReactNode
}
export const AppContext = createContext({});

export const AppProvider = ({children}: AppProviderProps) => {
    const {t} = useTranslation();
    useApiConfigurator();

    return <AppContext.Provider value={{}}>
        <UIKitContextProvider dictionary={{
            pagination: {
                elementsCounter: t('uiKit.pagination.outOf', {defaultValue: 'out of'}), // "out of" in "1 out of 5"
                goTo: t('uiKit.pagination.goTo', {defaultValue: 'Go to'}), // "Go to" page number (navigation input)
                itemsPerPage: t('uiKit.pagination.results', {defaultValue: "Results on page"}), // "Results on page:"
                prev: '', // "previous page" button text
                next: '' // "next page" button text
            }
        }} theme={'capyfast'}>
            {children}
            <div id={"modal-root"}/>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition={'top-left'}/>
        </UIKitContextProvider>
    </AppContext.Provider>

}
export const useAppContext = () => {
    return useContext(AppContext);
}
