import {ReactComponent as InfoIcon} from "assets/svg/interface-icons/info.svg";

import Style from "../table/table.module.scss";

export const ListSkeleton = () => {
    const skeletonClass = 'rounded bg-label-sixth animate-pulse'
    return <div className={'rounded-xl border'}>
        {[0,1,2,3,4,5,6,7,8,9].map((num) => (
            <div key={num}
                 className={`${Style.item} cursor-pointer overflow-hidden text-label-secondary p-2 [&:not(:last-child)]:border-b hover:bg-interface-fifth/20`}>
                <div>
                    <div className={`${skeletonClass} h-5 w-1/2`}>

                    </div>
                </div>
                <div>
                    <div className={`${skeletonClass} h-5 ${num % 2 ? 'w-3/4' : 'w-4/5'}`}></div>
                </div>
                <div>
                    <div className={`${skeletonClass} h-5 ${num % 2 ? 'w-2/3' : 'w-full'}`}></div>
                </div>
                <div>
                    <div className={`${skeletonClass} h-5 w-full`}></div>
                </div>
                <div>
                    <div className={`${skeletonClass} h-5 w-full`}></div>
                </div>
                <div>
                    <div className={`${skeletonClass} h-5 w-full`}></div>
                </div>
                <div className={'flex justify-end'}><InfoIcon className={'w-4 h-4'}/></div>
            </div>
        ))}
    </div>
}
