import {ReactComponent as WrongIcon} from "assets/svg/interface-icons/wrong.svg";
import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";

import {usePayoutFlow} from "../../../../domain/payout/providers/payout-flow";
import { Trans, useTranslation } from "react-i18next";


export const ErrorPayout = () => {
    const {t} = useTranslation()
    const {
        onPayout,
        resetPayoutFlow,
    } = usePayoutFlow();

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-interface-danger'}><WrongIcon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>
            {t('Something went wrong')}
        </div>
        <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
        <Trans i18nKey="walletInstructions">
            If the money is still in the wallet, try to repeat the payout.
            <br />
            <br />
            If the money was removed from your wallet, but you didn’t receive it on your card – contact our support{' '}
            <a href="mailto:support@capyfast.com" className="underline text-brand-primary">support@capyfast.com</a>.
        </Trans>
    </div>
        <div className={'flex gap-1 justify-center'}>
            <Button size={ButtonSizes.xl}
                    onClick={resetPayoutFlow}
                    theme={ButtonThemes.ghostFill}
            >{t('Go back')}</Button>
            <Button size={ButtonSizes.xl}
                    onClick={onPayout}>
                {t('Repeat payout')}
            </Button>
        </div>
    </div>
}
