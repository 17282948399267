import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

import {Paths} from "../../../../../core/router";

export const Tier2Complete = ({onFinish}: {onFinish: () => unknown}) => {
    const navigate = useNavigate();
    const {t} = useTranslation()


    return <div className={'flex flex-col justify-center items-center w-full h-full py-16'}>
        <img src={'/images/capybara-with-heart.svg'} alt={'Complete'}/>
        <div className={'py-6 flex flex-col justify-center items-center w-full  max-w-[22.5rem]'}>
            <h2 className={'font-heading font-extrabold text-header-03 text-center'}>
                {t('Thank you for completing verification!')}
            </h2>
            <div className={'text-body-01 text-label-tertiary text-center'}>
                {t('We will contact you if anything changes')}
            </div>
        </div>
        <div className={'flex justify-center gap-5'}>
            <Button size={ButtonSizes.xl}
                    onClick={() => navigate(Paths.HOME)}
                    theme={ButtonThemes.ghostFill}>
               {t(' Go to Catalog')}
            </Button>
            <Button size={ButtonSizes.xl}
                    onClick={() => onFinish()}>
                {t('Go to Payout')}
            </Button>
        </div>

    </div>
}
