import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {ReactComponent as WrongIcon} from "assets/svg/interface-icons/wrong.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Paths} from "../../../../core/router";

export const SomethingWentWrong = () => {
    const navigate = useNavigate();
    const {t}= useTranslation();

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-interface-danger'}><WrongIcon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>
            {t('Something went wrong')}
        </div>
        <div className={'text-center text-body-01 text-label-tertiary max-w-[20.5rem]'}>
            {t('Try to repeat the action a little later or contact us at')}
            {' '}<a href={'mailto:support@capyfast.com'} className={'underline text-brand-primary'}>support@capyfast.com</a>.
        </div>
        <div className={'flex gap-1 justify-center'}>
            <Button size={ButtonSizes.xl}
                    onClick={() => navigate(Paths.HOME)}
                    theme={ButtonThemes.interfaceOnsurface}>
                {t('Go Home')}
            </Button>
        </div>
    </div>
}
