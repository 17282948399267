import {Button, ButtonSizes} from "@design-system/ui-kit";
import {useRecoilState, useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";

import {
    disabledAutoPayoutAfterSelector,
    isOpenDisabledModalSelector,
    isOpenEnabledModalSelector
} from "../../../../core/store";
import {Paths} from "../../../../core/router";
import {HistoryTable} from "../history-table/history-table";
import {DisableAutoPayoutModal} from "../disable-auto-payout/disable-auto-payout";
import {usePayoutFlow} from "../../../../domain/payout/providers/payout-flow";
import {ReactivateAutoPayoutModal} from "../reactivate-auto-payout/reactivate-auto-payout";

type OneTimeInfoProps = {
    onStart?: () => unknown,
}
export const AutoPayoutInfo = ({onStart}: OneTimeInfoProps) => {
    const {t} = useTranslation()

    const [, setIsOpenDisableModal] = useRecoilState(isOpenDisabledModalSelector);
    const [, setIsOpenEnableModal] = useRecoilState(isOpenEnabledModalSelector);
    const {
        autoPayoutsConfig,
        refetchAutoPayoutConfig
    } = usePayoutFlow();
    const navigate = useNavigate();
    const daysToDisabled = useRecoilValue(disabledAutoPayoutAfterSelector)

    useEffect(() => {
        refetchAutoPayoutConfig();
    }, [refetchAutoPayoutConfig]);

    return <div className={`flex flex-col justify-between h-full`}>
        <div>
            <div className="text-header-03 text-label-primary font-heading font-extrabold mb-size-3">
                <Trans i18nKey="autoAdvancePayoutEnabled" values={{daysToDisabled}}>
                    Auto Advance Payout <span className="text-brand-primary">enabled</span>
                </Trans>
                {daysToDisabled > 0 && <span>{' '}{t('autoAdvancePayoutEnabledForNDaysMore', {daysToDisabled})}</span>}
            </div>
            <div className={'mt-size-3 text-body-02 text-label-secondary'}>
                {t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
            </div>
        </div>

        <div>
            <div className={'text-header-07 font-heading font-extrabold mb-size-0.5'}>
                {t('Recent payouts history')}
            </div>
            <HistoryTable/>
            {autoPayoutsConfig?.disable_at_datetime
                ? <div className={'mt-6 flex text-body-02 gap-6 items-center'}>
                    <Button size={ButtonSizes.xl}
                            onClick={() => setIsOpenEnableModal(true)}>
                        {t('Reactivate Auto-payout')}
                    </Button>
                    {/*font-bold text-label-danger*/}
                    <div dangerouslySetInnerHTML={{__html: t('autoPayoutWillBeTurnedOff', {daysToDisabled})}}/>
                </div>
                : <div className={'mt-6 text-body-02 underline cursor-pointer'}
                       onClick={() => setIsOpenDisableModal(true)}>
                    {t('Disable Auto Advance Payout')}
                </div>}

        </div>

        {autoPayoutsConfig?.disable_at_datetime
            ? <ReactivateAutoPayoutModal/>
            : <DisableAutoPayoutModal/>
        }


        <div className={'flex justify-between mt-5'}>
            <Button onClick={() => navigate(Paths.HISTORY)}
                    size={ButtonSizes.xl}>
                {t('Go to history')}
            </Button>
        </div>
    </div>
}
