import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";

import {Modal} from "../../../molecules/modal";
import {useVerificationService} from "../../../../domain/verification/services/verification";
import {useEnableAutoPayoutsMutation} from "../../../../domain/auto-payout/mutations/enable-auto-payouts";
import {useUser} from "../../../../domain/user/providers/user";

export const VerificationBeforeEnableAuto = () => {
    const {t} = useTranslation();
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const {
        enableAutoPayouts,
        isPending,
        isSuccess,
    } = useEnableAutoPayoutsMutation();
    const {
        onStartVerification,
        setOnFinish,
        isNeedVerification
    } = useVerificationService();

    const {
        isDisabledManualPayouts,
        refetchUserConfig,
    } = useUser();

    const onVerification = useCallback(() => {
        setIsStarted(true);
        if(isNeedVerification){
            setOnFinish(() => {
                enableAutoPayouts();
            });
            onStartVerification()
        }else {
            enableAutoPayouts()
        }
    }, [enableAutoPayouts, isNeedVerification, onStartVerification, setOnFinish])

    useEffect(() => {
        if(isSuccess) refetchUserConfig();
    }, [isSuccess, refetchUserConfig])

    const isShow = isDisabledManualPayouts && !isStarted;

    return <Modal isShow={isShow}>
        <div className={'flex flex-col gap-5'}>
            <div className={'flex justify-center'}>
                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M53.5513 10C55.4242 10 57.0954 11.1774 57.7284 12.9422L59.9835 19.2311C60.3414 20.2201 61.0362 21.051 61.9455 21.5778L68.6352 25.4444C69.5436 25.9692 70.6083 26.155 71.6405 25.9689L78.2103 24.7822C80.0552 24.4464 81.9118 25.3073 82.8491 26.9333L86.4004 33.0667C87.3402 34.6883 87.1626 36.7259 85.9565 38.16L81.6461 43.2622C80.9673 44.0645 80.5945 45.0818 80.5941 46.1333V53.8667C80.5945 54.9182 80.9673 55.9355 81.6461 56.7378L85.9565 61.84C87.1626 63.2741 87.3402 65.3118 86.4004 66.9333L82.8491 73.0667C81.9124 74.6909 80.0581 75.5516 78.2147 75.2178L71.6449 74.0311C70.6127 73.845 69.5481 74.0308 68.6397 74.5556L61.95 78.4222C61.0406 78.949 60.3459 79.7799 59.9879 80.7689L57.7329 87.0578C57.0993 88.8241 55.4258 90.0016 53.5513 90H46.4487C44.5758 90 42.9046 88.8226 42.2716 87.0578L40.0165 80.7689C39.6587 79.7812 38.9659 78.9507 38.0589 78.4222L31.3648 74.5556C30.4564 74.0308 29.3917 73.845 28.3595 74.0311L21.7897 75.2178C19.9448 75.5536 18.0882 74.6927 17.1509 73.0667L13.5996 66.9333C12.6598 65.3118 12.8374 63.2741 14.0435 61.84L18.3539 56.7378C19.0327 55.9355 19.4055 54.9182 19.4059 53.8667V46.1333C19.4055 45.0818 19.0327 44.0645 18.3539 43.2622L14.0879 38.16C12.8818 36.7259 12.7042 34.6883 13.644 33.0667L17.1953 26.9333C18.132 25.3091 19.9863 24.4484 21.8297 24.7822L28.3995 25.9689C29.4317 26.155 30.4963 25.9692 31.4047 25.4444L38.0988 21.5778C39.0058 21.0493 39.6986 20.2188 40.0565 19.2311L42.3115 12.9422C42.9396 11.1911 44.5905 10.017 46.4487 10H53.5513Z"
                          stroke="#93B800" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M65.25 41L47.125 59L36.25 48.2" stroke="#93B800" strokeWidth="3" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={'text-header-03 font-extrabold'}>
                <Trans i18nKey={'startVerificationPopup.title'} components={[<span className={'text-brand-primary'}/>]}/>
            </div>
            <div className={'text-body-01 leading-[200%]'}>
                <Trans i18nKey={'startVerificationPopup.description'}/>
            </div>
            <div className={'flex justify-end'}>
                <Button size={ButtonSizes.xl}
                        loading={isPending}
                        onClick={onVerification}
                        theme={ButtonThemes.interfaceAdPrimary}>
                    {t('startVerificationPopup.start')}
                </Button>
            </div>
        </div>
    </Modal>
}
