import {useRecoilState, useRecoilValue} from "recoil";
import {isNeedSignDocsAtom, JWTPayloadSelector} from "core/store";
import {UserLegalDocumentsSchemaType} from "@capyfast/contracts";

import {useUserConfigQuery} from "../queries/user-config";

export const useUserService = () => {
    const [isNeedSignDocs, setIsNeedSignDocs] = useRecoilState(isNeedSignDocsAtom);
    const userDataPayload = useRecoilValue(JWTPayloadSelector);


    const {
        user,
        isUserLoaded,
        refetch: refetchUserConfig
    } = useUserConfigQuery();

    return {
        payload: userDataPayload,
        isUserLoaded,
        isNeedSignDocs,
        refetchUserConfig,
        setIsNeedSignDocs,
        signDocs: user.onboarding_legal_documents?.filter((item: UserLegalDocumentsSchemaType) => !item.is_signed) ?? [],
        isDisabledManualPayouts: user.is_disabled_manual_payouts
    }
}
