import {createContext, ReactNode, useContext} from "react";

import {useTier2Service} from "../services/tier2";

const Tier2Context = createContext<ReturnType<typeof useTier2Service> | null>(null);

export const Tier2ContextProvider = ({children}: {children: ReactNode}) => {
    const context = useTier2Service();
    if(!context) throw new Error('tier2 provider not set');

    return <Tier2Context.Provider value={context}>
        {children}
    </Tier2Context.Provider>
}

export const useTier2 = () => {
    const context = useContext(Tier2Context);

    if(!context) throw new Error('tier2 provider not set');

    return context;
}
