import {usePayoutFlow} from "../../../../domain/payout/providers/payout-flow";
import {AutoPayoutInfo} from "../auto-payouts-info/auto-payout-info";
import {ActivateAutoPayouts} from "../activate-auto-payouts/activate-auto-payouts";
import {PayoutInfo} from "../payout-info/payout-info";

export const Information = () => {
    const {
        onPayout,
        isNoNewPayout,
        isAutoPayoutEnabled
    } = usePayoutFlow();

    if(isAutoPayoutEnabled) return <AutoPayoutInfo/>;

    return isNoNewPayout
        ? <ActivateAutoPayouts/>
        : <PayoutInfo onStart={() => onPayout()}/>
}
