import {useQuery} from "@tanstack/react-query";
import {getUserWalletBalances} from "@capyfast/api";
import {WalletBalancesSchema} from "@capyfast/contracts";

import {QueriesKeys} from "../../../core/constants/queries-keys";

const defaultData =  {
    USD: 0,
    EUR: 0,
    GBP: 0
}
export const useWalletBalances = () => {
    const {
        data,
        isError,
        isFetching,
        isLoading,
        isPending,
        refetch,
    } = useQuery({
        queryKey: [QueriesKeys.WALLET_BALANCES],
        queryFn: () => {
            return getUserWalletBalances()
                .then(response => WalletBalancesSchema.parse(response.data))
                .catch(() => defaultData)
        },
        placeholderData: defaultData,
    })

    return {
        walletsData: data ?? defaultData,
        isError,
        isFetching,
        isLoading,
        isPending,
        refetch,
    }
}
