import {useTranslation} from "react-i18next";

import {ServiceNotAvailable} from "../../../@errors/service-not-available/service-not-available";

export const FailedTier1 = () => {
    const {t} = useTranslation()

    return <ServiceNotAvailable className={'h-full'}
                                title={t('verification.tier1.serviceNotAvailable.title')}
                                description={t('verification.tier1.serviceNotAvailable.description')}/>
}
