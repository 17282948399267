import {useMemo} from "react";
import { useTranslation } from 'react-i18next';

type StepsIndicatorProps = {
    steps: {
        title: string,
        isActive: boolean,
    }[]
}
export const StepsIndicator = ({steps}: StepsIndicatorProps) => {
    const {t} = useTranslation()
    const activeStep = steps.find((step) => step.isActive);

    const title = useMemo(() => {
        switch (activeStep?.title){
            case 'q1': return t('Step 1 - Verification process')
            case 'q2': return t('Step 2 - Verification process')
            case 'q3': return t('Step 3 - Verification process')
            default: return activeStep?.title
        }
    }, [activeStep?.title])

    return <div>
        <div className={'flex flex-nowrap w-full gap-2'}>
            {steps.map((step) => {
                return <div key={step.title}
                            className={`h-[0.3125rem] rounded grow ${step.isActive ? 'bg-brand-primary' : 'bg-background-quaternary'}`}>
                </div>
            })}
        </div>
        {activeStep && <div className={'mt-size-0.5 text-body-04 text-label-fifth'}>
            {title}
        </div>}
    </div>
}
