import {useMemo} from "react";
import {useTranslation} from "react-i18next";


export const Contents = [
    {
        title: 'faq.1.title',
        key: 1,
        content: 'faq.1.content'
    },
    {
        title: 'faq.2.title',
        key: 2,
        content: 'faq.2.content'
    },
    {
        title: 'faq.3.title',
        key: 3,
        content: 'faq.3.content'
    },
    {
        title: 'faq.4.title',
        key: 4,
        content: 'faq.4.content'
    },
    {
        title: 'faq.5.title',
        key: 5,
        content: 'faq.5.content',
    },
    {
        title: 'faq.6.title',
        key: 6,
        content: 'faq.6.content',
    },
    {
        title: 'faq.7.title',
        key: 7,
        content: 'faq.7.content',
    },
    {
        title: 'faq.8.title',
        key: 8,
        content: 'faq.8.content'
    },
    {
        title: 'faq.9.title',
        key: 9,
        content: 'faq.9.content'
    },
    {
        title: 'faq.10.title',
        key: 10,
        content: 'faq.10.content',
    },
]

export const useFaqContent = () => {
    const {t, i18n} = useTranslation();
    const content = useMemo(() => {
        return Contents
            .map((i) => {
                return {
                    title: t(i.title, {defaultValue: 'off'}),
                    key: i.key,
                    content: t(i.content, {defaultValue: null})
                }
            })
            .filter((i) => i.title !== 'off')
    }, [t, i18n.language])

    return {
        content
    }
}
