import {atom} from "recoil";
import {instantPayoutUserType} from "@capyfast/contracts";

export const isUserLoadedAtom = atom({
    key: 'isUserLoadedAtom',
    default: false,
})
export const isNeedSignDocsAtom = atom({
    key: 'isNeedSignDocsAtom',
    default: false,
});

export const instancePayoutClientTypeAtom = atom<undefined | instantPayoutUserType>({
    key: 'instancePayoutClientTypeAtom',
    default: undefined,
});


export const isUserBlockedAtom = atom({
    key: 'isUserBlockedAtom',
    default: false,
})
