import {atom, selector} from "recoil";
import {z} from "zod";

export enum QuestionnaireSteps {
    Q1 = 'q1',
    Q2 = 'q2',
    Q3 = 'q3',
    Success = 'Success',
}

export const tier2StepsAtom = atom({
    key: 'tier2StepsAtom',
    default: [
        {title: QuestionnaireSteps.Q1, isActive: true},
        {title: QuestionnaireSteps.Q2, isActive: false},
        {title: QuestionnaireSteps.Q3, isActive: false},
    ]
});

export const tier2StepActiveSelector = selector({
    key: 'tier2StepActiveSelector',
    get: ({get}) => {
        return get(tier2StepsAtom).find((s) => s.isActive);
    },
    set: ({set}, newValue: any) => {
        return set(tier2StepsAtom, (prev) => {
            return prev.map((item) => ({title: item.title, isActive: item.title === newValue.title}))
        })
    }
})


export const answersStep1VariantsAtom = atom<{ [key: string]: string }>({
    key: 'answersStep1VariantsAtom',
    default: {
        cashback: 'Cashback',
        coupon: 'Coupon websites',
        context: 'Contextual advertising',
        brand_bidding: 'Brand bidding',
        doorway: 'Doorway traffic',
        incentive: 'Incentive traffic',
        social_media_target: 'Targeted advertising on social media',
        toolbar: 'Toolbar',
        pop_up: 'Pop-up / Click-under ads',
        other: 'Other'
    }
});

export const q1ValueAtom = atom<{ [key: string]: boolean }>({
    key: 'q1ValueAtom',
    default: {},
})

export const q1OtherValueAtom = atom<string>({
    key: 'q1OtherValueAtom',
    default: ''
})

export const q1PrepareDataSelector = selector<string>({
    key: 'q1PrepareDataSelector',
    get: ({get}) => {
        const q1 = get(q1ValueAtom);
        return Object.keys(q1)
            .filter(network => q1[network])
            .map((network) => {
                if(network === 'other') return `${network} - ${get(q1OtherValueAtom)}`
                return network;
            })
            .join(';') ?? ''
    }
})

export const networkVariantsAtom = atom<{ [key: string]: string }>({
    key: 'networkVariantsAtom',
    default: {
        awin: 'AWIN',
        admitad: 'Admitad',
        cj: 'CJ',
        share_a_sale: 'ShareASale',
        rakuten: 'Rakuten',
        trade_doubler: 'TradeDoubler',
        impact: 'Impact',
        clickbank: 'Clickbank',
        flexoffers: 'Flexoffers',
        other: 'Other'
    }
});

export const q2ValueAtom = atom<{ [key: string]: boolean }>({
    key: 'q2ValueAtom',
    default: {},
})

export const q2OtherValueAtom = atom<string>({
    key: 'q2OtherValueAtom',
    default: ''
})


export const Tier2Step1Atom = atom({
    key: 'Tier2Step1Atom',
    default: {}
})

export const q3ValueItemSchema = z.string().min(1).refine(
    value => {
        return !isNaN(value as any) && Number(value) >= 0 && Number(value) <= 100
    },
    {
        message: "Value must be a number between 0 and 100",
        path: [], // optional: you can specify the path of the field
    }
);
export const q3ValuesSchema = z.record(z.string(), q3ValueItemSchema).refine(
    data => Object.keys(data).length > 0,
    { message: "At least one field is required" }
)

export const q3ValueAtom = atom<z.infer<typeof q3ValuesSchema>>({
    key: 'q3ValueAtom',
    default: {},
})

export const isQ1VerifiedSelector = selector<boolean>({
    key: 'isQ1VerifiedSelector',
    get: ({get}) => {
        const q1v = get(q1ValueAtom);
        if (!Object.keys(q1v).length) return false;
        if (!Object.keys(q1v).filter((k) => q1v[k]).length) return false;
        if (q1v?.other && !get(q1OtherValueAtom)) return false;

        return true;
    }
})

export const isQ2VerifiedSelector = selector<boolean>({
    key: 'isQ2VerifiedSelector',
    get: ({get}) => {
        const q2v = get(q2ValueAtom);
        if (!Object.keys(q2v).length) return false;
        if (!Object.keys(q2v).filter((k) => q2v[k]).length) return false;
        if (q2v?.other && !get(q2OtherValueAtom)) return false;

        return true;
    }
})

export const q2PrepareDataSelector = selector<string>({
    key: 'q2PrepareDataSelector',
    get: ({get}) => {
        const q2 = get(q2ValueAtom);
        return Object.keys(q2)
            .filter(network => q2[network])
            .map((network) => {
                if(network === 'other') return `${network} - ${get(q2OtherValueAtom)}`
                return network;
            })
            .join(';') ?? ''
    }
})

export const isQ3VerifiedSelector = selector<boolean>({
    key: 'isQ3VerifiedSelector',
    get: ({get}) => {
        try{
            q3ValuesSchema.parse(get(q3ValueAtom))
            return true;
        }catch (e){
            return false;
        }
    }
})

export const q3PrepareDataSelector = selector<string>({
    key: 'q3PrepareDataSelector',
    get: ({get}) => {
        const q3 = get(q3ValueAtom);
        return Object.keys(q3)
            .filter(network => q3[network])
            .map((network) => {
                return `${network} - ${q3[network]}`;
            })
            .join(';') ?? ''
    }
})
