import sha256 from 'js-sha256'

export function generateState() {
    const length = 30;
    let stateValue = "";
    const alphaNumericCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const l = alphaNumericCharacters.length;
    for(let i=0; i<length; i++) {
        stateValue += alphaNumericCharacters.charAt(Math.floor(Math.random()*l));
    }
    return stateValue;
}

export function generateCodeVerifier() {
    let codeVerifier = "";
    const randomByteArray = new Uint8Array(32);
    window.crypto.getRandomValues(randomByteArray);
    // @ts-ignore
    codeVerifier = base64urlencode(randomByteArray);
    return codeVerifier;
}

/* should not be called from a reducer */
export async function generateCodeChallenge(codeVerifier: string) {
    let codeChallenge = "";
    const textEncoder = new TextEncoder();
    const encodedValue = textEncoder.encode(codeVerifier);

    const digest = sha256.sha256.digest(encodedValue);
    codeChallenge = base64urlencode(Array.from(new Uint8Array(digest)));
    return codeChallenge;
}

function base64urlencode(sourceValue: number[]) {
    const stringValue = String.fromCharCode.apply(null, sourceValue);
    const base64encoded = btoa(stringValue);
    return base64encoded.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}