import {useQuery} from "@tanstack/react-query";
import {getAutoPayoutsConfig} from "@capyfast/api/dist/common-api/methods/auto-payouts";
import {useRecoilState, useRecoilValue} from "recoil";

import {AutoPayoutConfigAtom, isApiReadySelector} from "../../../core/store";
import {QueriesKeys} from "../../../core/constants/queries-keys";

export const useAutoPayoutsConfigQuery = ({enabled = true}: {enabled: boolean }) => {
    const [, setAutoPayoutsConfig] = useRecoilState(AutoPayoutConfigAtom)
    const isReady = useRecoilValue(isApiReadySelector)
    const {
        data,
        isLoading,
        isError,
        isSuccess,
        isPending,
        refetch,
    } = useQuery({
        queryKey: [QueriesKeys.AUTO_PAYOUT_CONFIG],
        queryFn: async () => {
            let response = await getAutoPayoutsConfig();
            setAutoPayoutsConfig(response.data)
            return response.data;
        },
        enabled: isReady && enabled,
        refetchOnMount: true,
        staleTime: 1000 * 2,
        refetchOnWindowFocus: true,
    })

    return {
        data,
        isLoading,
        isError,
        isSuccess,
        isPending,
        refetch,
    }
}
