import {atom, selector} from "recoil";

import {Data} from "../../components/@pages/home/data";

export type PlatformSchema = {
    id: number,
    title: string,
    description: string,
    short_description: string,
    url: string,
    logo_url: string | null,
    key: string,
    active: boolean
}

export const PlatformsAtom = atom<PlatformSchema[]>({
    key: 'PlatformsAtom',
    default: Data
})

export const AvailablePlatformsAtom = selector<PlatformSchema[]>({
    key: 'AvailablePlatformsAtom',
    get: ({get}) => {
        return get(PlatformsAtom).filter(item => item.active)
    }
})

export const ComingSoonPlatformsAtom = selector<PlatformSchema[]>({
    key: 'ComingSoonPlatformsAtom',
    get: ({get}) => {
        return get(PlatformsAtom).filter(item => !item.active)
    }
})
