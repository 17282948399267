import {useQuery} from "@tanstack/react-query";
import {getSumsubToken} from "@capyfast/api";
import {useRecoilValue} from "recoil";

import {isApiReadySelector, isAutoPayoutSelector} from "../../../core/store";

type useSumsubGetTokenQueryOptions= {
    enabled: boolean
}
export const useSumsubGetTokenQuery = ({enabled}: useSumsubGetTokenQueryOptions) => {
    const isReady = useRecoilValue(isApiReadySelector)
    const isAuto = useRecoilValue(isAutoPayoutSelector)
    const {
        isLoading,
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['sumsub-token', isAuto],
        queryFn: () => getSumsubToken({payout_type: isAuto ? 'auto' : 'manual'}).then(response => response.data.token),
        refetchInterval: false,
        enabled: enabled && isReady,
    })

    return {
        isError,
        isLoading,
        isFetching,
        token: data,
        refetch,
    }
}
