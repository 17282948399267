import {useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import cookie from "js-cookie";
import {configureCommonApiClient, getCommonApiClient} from "@capyfast/api";
import axios, {AxiosError, AxiosRequestConfig} from "axios";

import {authTokenAtom, isInjectTokenApiAtom} from "../store";

import {useAppAuth} from "./app-auth";

export const useApiConfigurator = () => {
    const token = useRecoilValue(authTokenAtom);
    const [, setInject] = useRecoilState(isInjectTokenApiAtom);
    const {logOut, isRefreshing, onRefreshToken} = useAppAuth();
    const CSRF = cookie.get('csrftoken');

    useEffect(() => {
        if (token) {
            configureCommonApiClient(axios.create({
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRFToken': CSRF,
                }
            }));
            setInject(true);
        } else {
            configureCommonApiClient(axios.create({
                headers: {
                    Authorization: `Not set`,
                    'X-CSRFToken': CSRF,
                }
            }));
        }
    }, [CSRF, setInject, token]);

    useEffect(() => {
        const interceptorId = getCommonApiClient()?.interceptors?.response?.use(
            (response) => response,
            async (error: AxiosError & {
                config: AxiosRequestConfig & { _retry: boolean }
            }) => {
                const originalRequest = error.config;

                if (error?.response && error.response?.status === 401) {
                    console.log('401',error?.response )
                    return onRefreshToken()
                        .then(async (value) => {
                            if (originalRequest.headers && value?.access) {
                                originalRequest.headers['Authorization'] = 'Bearer ' + value?.access;
                                return await getCommonApiClient().request(originalRequest);
                            }
                        })
                        .catch(() => {
                            return Promise.reject(error);
                        })
                }
                return Promise.reject(error);
            }
        );

        return () => {
            interceptorId && getCommonApiClient().interceptors.response.eject(interceptorId)
        }
    }, [isRefreshing, logOut, onRefreshToken]);

}
