import {useTier2} from "domain/verification/providers";

import {useRecoilValue} from "recoil";

import {answersStep1VariantsAtom, QuestionnaireSteps} from "../../../../core/store/tier-2.store";
import {StepsIndicator} from "../../../molecules/steps-indicator";

import {Tier2Complete} from "./steps/complete";
import {VerificationTier2Q2} from "./steps/q2";
import {VerificationTier2Q1} from "./steps/q1";
import {VerificationTier2Q3} from "./steps/q3";

type Tier2Props = {
    onFinish: () => unknown,
}

export const Tier2 = ({onFinish}: Tier2Props) => {
    const answersVariants = useRecoilValue(answersStep1VariantsAtom);
    const {
        steps,
        step,
        isComplete,
    } = useTier2();

    if(isComplete) return <div>
        <Tier2Complete onFinish={onFinish}/>
    </div>

    return <div className={'space-y-size-3'}>
            <StepsIndicator steps={steps}/>
            {step.title === QuestionnaireSteps.Q1 && <VerificationTier2Q1/>}
            {step.title === QuestionnaireSteps.Q2 && <VerificationTier2Q2/>}
            {step.title === QuestionnaireSteps.Q3 && <VerificationTier2Q3/>}
        </div>
}
