import {ReactComponent as Icon} from "assets/svg/whats-up/whats-up-icon.svg";

export const WhatsUpButton = () => {
    return <div onClick={() => {
        window.open('https://wa.me/message/WTZUXM4DCET7B1', '_blank')
    }} className={'fixed bottom-8 right-4 transition-all shadow w-14 p-2 border-2 border-white aspect-square bg-[#57D163] text-label-onsurface rounded-full cursor-pointer hover:bg-[#23B33A] hover:shadow-2xl'}>
        <Icon/>
    </div>

}
