import {Button, ButtonSizes} from "@design-system/ui-kit";
import {useRecoilValue} from "recoil";
import { Trans, useTranslation } from "react-i18next";

import {NoNewPayoutAlert} from "../no-new-payout-alert";
import {isAutoPayoutsActiveSelector} from "../../../../core/store";
import {ConditionsOfAutoPayout} from "../conditions-of-auto-payout";
import {AcceptTermsAndConditions} from "../accept-terms-and-conditions/accept-terms-and-conditions";
import {useEnableAutoPayoutFlow} from "../../../../domain/auto-payout/services/enable";

type OneTimeInfoProps = {
    disabled?: boolean
}
export const ActivateAutoPayouts = ({disabled}: OneTimeInfoProps) => {
    const isAutoPayoutActive = useRecoilValue(isAutoPayoutsActiveSelector);
    const {t} = useTranslation()

    const {
        onEnable,
        isPending,
        isAccept,
    } = useEnableAutoPayoutFlow();

    return <div className={`flex flex-col justify-between h-full ${disabled ? 'pointer-events-none opacity-50' : ''}`}>
         <div>
         <div className="text-header-03 text-label-primary font-heading font-extrabold mb-size-3">
            <Trans i18nKey="requestForAutoAdvance">
                Request for <span className="text-brand-primary">Auto Advance Payout</span>
            </Trans>
        </div>
             <div className={'mt-size-3 text-body-02 text-label-secondary'}>
                {t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
             </div>
         </div>
        <div>
            <NoNewPayoutAlert className={'my-size-3'}/>
        </div>
        <div className={'mt-size-3'}>
            <ConditionsOfAutoPayout/>
        </div>
        <div className={'mt-size-3'}>
            <AcceptTermsAndConditions/>
        </div>
        {!disabled && <div className={'flex justify-between mt-5'}>
            <Button onClick={() => {onEnable()}}
                    loading={isPending}
                    disabled={!isAccept && !isAutoPayoutActive}
                    size={ButtonSizes.xl}>
               {t('Activate Auto Payouts')}
            </Button>
        </div>}
    </div>
}
