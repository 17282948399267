import {useQuery} from "@tanstack/react-query";
import {useRecoilState, useRecoilValue} from "recoil";
import {getUserConfig} from "@capyfast/api";
import {UserConfigSchema, UserConfigSchemaType} from "@capyfast/contracts";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {
    instancePayoutClientTypeAtom,
    isApiReadySelector,
    isNeedSignDocsAtom,
    isUserBlockedAtom,
    isUserLoadedAtom
} from "../../../core/store";

const defaultData: UserConfigSchemaType = {
    admitad_defective_pi_ration: false,
    admitad_min_registration: false,
    is_blacklist: false,
    onboarding_legal_documents: [],
};
export const useUserConfigQuery = () => {
    const isReady = useRecoilValue(isApiReadySelector);
    const [isUserLoaded, setIsUserLoaded] = useRecoilState(isUserLoadedAtom)
    const [, setIsUserBlocked] = useRecoilState(isUserBlockedAtom);
    const [, setIsNeedSignDocs] = useRecoilState(isNeedSignDocsAtom);
    const [, setIsInstancePayoutClient] = useRecoilState(instancePayoutClientTypeAtom);
    const {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
    } = useQuery({
        queryKey: [QueriesKeys.GET_USER_CONFIG],
        queryFn: async () => {
            return await getUserConfig()
                .then(response => UserConfigSchema.parse(response.data))
                .then(data => {
                    setIsUserLoaded(true);
                    setIsUserBlocked(data.is_blacklist)
                    setIsInstancePayoutClient(data?.instant_payout_user_type)
                    setIsNeedSignDocs(!!data?.onboarding_legal_documents
                        .filter((item) => item.is_required)
                        .filter((item) => !item.is_signed).length);
                    return data;
                }).catch((e) => {
                    setIsUserLoaded(false);
                    return e;
                })
        },
        enabled: isReady,
        placeholderData: defaultData,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    return {
        user: data ?? defaultData,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
        isUserLoaded,
    }
}
