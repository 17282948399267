import {useMutation, useQueryClient} from "@tanstack/react-query";
import {enableAutoPayouts} from "@capyfast/api";

import {MutationKeys, QueriesKeys} from "../../../core/constants/queries-keys";

export const useEnableAutoPayoutsMutation = () => {
    const client = useQueryClient();
    const {
        mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    } = useMutation({
        mutationKey: [MutationKeys.ENABLE_AUTO_PAYOUT],
        mutationFn: () => {
            return enableAutoPayouts();
        },
        onSuccess:async () => {
            return await client.refetchQueries({
                queryKey: [QueriesKeys.AUTO_PAYOUT_CONFIG]
            })
        }
    })

    return {
        enableAutoPayouts: mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    }
}
