import {Button, ButtonThemes} from "@design-system/ui-kit";
import {useTranslation} from 'react-i18next';

import {Card} from "components/atoms/card/card";


export const NotFoundPage = () => {
    const {t} = useTranslation()
    return <Card className={'p-8'}>
        <div className={'prose'}>
            <h1>{t('Page not found')}</h1>
            <p>{t("Lorem ipsum dolor sit amet")}</p>
            <Button theme={ButtonThemes.interfaceOnsurface}>{t('Go Home')}</Button>
        </div>
    </Card>
}
