import {OIC_ENDPOINT, ROOT_URL, CALLBACK_PATH, CLIENT_ID} from '../constants'


export const getAuthPageURI = (pkceState: string, codeChallenge: string) => {
    let authorizationUrl = OIC_ENDPOINT + "/auth";
    authorizationUrl += "?client_id=" + CLIENT_ID;
    authorizationUrl += "&response_type=code";
    authorizationUrl += "&scope=openid profile email roles web-origins";
    authorizationUrl += "&redirect_uri=" + ROOT_URL + CALLBACK_PATH;
    authorizationUrl += "&state=" + pkceState;
    authorizationUrl += "&code_challenge=" + codeChallenge;
    authorizationUrl += "&code_challenge_method=S256";

    return authorizationUrl;
}

export const getAuthCode = async (pkceState: string | null, codeChallenge: string | null) => {
    if(pkceState && codeChallenge) window.location.href = getAuthPageURI(pkceState, codeChallenge);
}