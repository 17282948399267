import {Button, ButtonSizes, ButtonThemes, Checkbox} from "@design-system/ui-kit";
import {ReactComponent as DocIcon} from "assets/svg/onboarding/doc.svg";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserLegalDocumentsSchemaType} from "@capyfast/contracts";

import {useSignDocsMutation} from "../../../../domain/user/mutations/sign-docs";
import {useUser} from "../../../../domain/user/providers/user";

type ConditionsProps = {
    onSuccess: () => unknown,
    onBack: () => unknown
};
export const Conditions = ({onSuccess, onBack}: ConditionsProps) => {
    const {t} = useTranslation()

    const [agrees, setAgrees] = useState(() => new Set<number>())
    const {signDocs} = useUser();

    const {
        signDocuments,
        isPending,
        isSuccess,
        reset
    } = useSignDocsMutation();

    const onSubmit = useCallback(() => {
        const ids = Array.from(agrees);
        signDocuments(ids);
    }, [agrees, signDocuments])

    const isValid = useMemo(() => {
        const requiredDocs = signDocs
            .filter((doc:UserLegalDocumentsSchemaType) => doc.is_required)
            .map((doc:UserLegalDocumentsSchemaType)=>doc.id);
        for (const v of requiredDocs) {
            if (!agrees.has(v)) {
                return false;
            }
        }
        return true;
    }, [agrees, signDocs]);

    useEffect(() => {
        if (isSuccess) {
            reset();
            onSuccess();
        }
    }, [isSuccess, onSuccess, reset])

    return <div className={'max-w-[37.5rem]'}>
        <div className={'space-y-6'}>
            <div
                className={'w-10 mx-auto aspect-square rounded-full border-s3 border-brand-primary text-brand-primary flex justify-center items-center font-heading text-[1.4rem]'}>
                2
            </div>
            <div className={'text-header-03 font-heading font-extrabold text-center '}>
                {t('Please read and agree with all the conditions')}
            </div>
            <div className={'text-body-01 text-label-tertiary text-center'}>
                {t('We will also ask you to verify your information when connecting to partner networks')}
            </div>
            <div className={'flex flex-col gap-size-0.5'}>
                {signDocs.map((doc: UserLegalDocumentsSchemaType) => {
                    return <div key={doc.id} className={'rounded-xl bg-background-tertiary p-6 flex flex-col gap-3'}>
                        <div className={'flex justify-between gap-6 items-center'}>
                            <div className={'flex gap-3 text-body-02 font-medium'}>
                                <DocIcon/>
                                <div>
                                    {t(`documents.${doc.key}.title`, doc.key)}
                                    {doc.is_required && <span className={'text-label-danger ml-1'}>*</span>}
                                </div>

                            </div>
                            {doc.file_url && <Button theme={ButtonThemes.interfaceOnsurface}
                                                     onClick={() => doc.file_url && window.open(doc.file_url, '_blank')}
                                                     size={ButtonSizes.l}>
                                {t('Read')}
                            </Button>}
                        </div>
                        <Checkbox checked={agrees.has(doc.id)}
                                  onChange={(v) => {
                                      setAgrees((prev) => {
                                          if (v) return new Set(prev).add(doc.id);
                                          else {
                                              const newV = new Set(prev);
                                              newV.delete(doc.id);
                                              return newV;
                                          }
                                      })
                                  }}
                                  label={<span className={'text-body-03 text-label-tertiary'}>
                        {t(`onboarding.documentsCheckboxLabel.${doc.key}`, {defaultValue: t('I’ve read and agree with this document')})}
                    </span>}/>
                    </div>
                })}
            </div>
        </div>
        <div className={'flex justify-between mt-10'}>
            <Button theme={ButtonThemes.interfaceOnsurface}
                    size={ButtonSizes.xl}
                    onClick={onBack}>
                {t('Back')}
            </Button>
            <Button size={ButtonSizes.xl}
                    disabled={!isValid || isSuccess}
                    loading={isPending}
                    onClick={onSubmit}>
                {t('Next')}
            </Button>
        </div>
    </div>
}
