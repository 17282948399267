import {useRecoilState} from "recoil";
import {PassportSchema} from "@capyfast/contracts";
import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useEffect, useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";

import {ListSelect} from "../../../atoms/list-select/list-select";
import {SelectedPassportTypeAtom} from "../../../../domain/payout/store/flow";
import {useCreatePassportMutation} from "../../../../domain/passport/mutations/create-passport";
import {useUser} from "../../../../domain/user/providers/user";

type CreatePassportProps = {
    onSuccess: (passport: PassportSchema) => unknown,
    onError?: () => unknown,
    onBack?: () => unknown
}
export const CreatePassport = ({onSuccess, onError, onBack}: CreatePassportProps) => {
    const {t, i18n} = useTranslation()
    const {isDisabledManualPayouts} = useUser();

    const [passportType, setPassportType] = useRecoilState(SelectedPassportTypeAtom);
    const PassportOptions: { label: string, value: PassportSchema['legal_personality'] }[] = useMemo(() => [
        {label: t('createPassport.select.juridical', {defaultValue: 'Company'}), value: 'juridical'},
        {label: t('createPassport.select.natural', {defaultValue: 'Person'}), value: 'natural'},
    ], [t, i18n.language])

    const {
        passport,
        createPassport,
        isSuccess,
        isError,
        isPending
    } = useCreatePassportMutation();

    useEffect(() => {
        if (isSuccess && passport) {
            onSuccess(passport);
        }
    }, [isSuccess, onSuccess, passport]);

    useEffect(() => {
        if(isError) onError?.()
    }, [isError, onError])

    return <div className={'h-full flex flex-col justify-between'}>
        <div className={'space-y-size-3'}>
            <div className={'text-body-04 text-label-fifth'}>{t('Step 1 - Verification process')}</div>
            <div className="text-header-04 font-extrabold mb-6">
                <Trans i18nKey="withdrawalVerification">
                    <span className="text-brand-primary">Hi,</span> you're doing great! For withdrawals, we have to ask
                    you to verify who you are.
                </Trans>
            </div>
            <div className={'text-body-02 text-label-tertiary'} dangerouslySetInnerHTML={{__html: t('createPassport.description')}}/>
            <ListSelect items={PassportOptions} value={passportType ?? ''} onSelectItem={(v) => {
                setPassportType(v as PassportSchema['legal_personality'])
            }}/>
        </div>
        <div className={'flex justify-between items-center mt-size-3'}>
            <Button theme={ButtonThemes.interfaceOnsurface}
                    size={ButtonSizes.xl}
                    disabled={isDisabledManualPayouts}
                    onClick={onBack}>
                {t('Back')}
            </Button>
            <Button size={ButtonSizes.xl}
                    loading={isPending}
                    onClick={() => !!passportType && createPassport({type: passportType})}
                    disabled={!passportType}>
                {t('Next')}
            </Button>
        </div>
    </div>
}
