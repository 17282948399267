import {useMutation} from "@tanstack/react-query";
import {signDocuments} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useSignDocsMutation = () => {
    const {
        data,
        mutate,
        mutateAsync,
        isSuccess,
        isError,
        isPending,
        reset,
    } = useMutation({
        mutationKey: [MutationKeys.SIGN_DOCUMENTS],
        mutationFn: (ids: number[]) => {
            return signDocuments(ids).then(response => response.data);
        }
    })

    return {
        signDocuments: mutate,
        signDocumentsAsync: mutateAsync,
        data,
        isSuccess,
        isError,
        isPending,
        reset,
    }
}
