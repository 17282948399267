import {useRecoilState, useRecoilValue} from "recoil";
import {refreshToken} from "@capyfast/api";
import {useCallback} from "react";

import {authRefreshTokenAtom, authTokenAtom, isAuthSelector, isRefreshInProgressAtom} from "../store";
import {SSOLogout, ssoRefreshToken} from "../keycloak";
import {KeycloakStorageKeys} from "../keycloak/constants";

declare global {
    interface Window {
        refreshTokenRequest: Promise<any> | null | undefined;
    }
}

export const useAppAuth = () => {
    const [token, setToken] = useRecoilState(authTokenAtom);
    const [, setRefreshToken] = useRecoilState(authRefreshTokenAtom);
    const [isRefreshing, setIsRefreshing] = useRecoilState(isRefreshInProgressAtom);
    const isAuth = useRecoilValue(isAuthSelector);

    const onAuth = useCallback((token: string, refresh: string) => {
        setToken(token);
        setRefreshToken(refresh);
    }, [setRefreshToken, setToken])

    const logOut = useCallback(() => {
        setToken(null);
        setRefreshToken(null);
        console.log('# Clear session storage')
        sessionStorage.clear();
        SSOLogout();
    }, [setRefreshToken, setToken]);

    let refreshTokenPromise: Promise<any> | null = null;

    const onRefreshToken = useCallback(async () => {
        if (!isRefreshing) {
            if (!refreshTokenPromise) {
                refreshTokenPromise = new Promise(async (resolve, reject) => {
                    setIsRefreshing(true);
                    try {
                        const ssoData = await ssoRefreshToken(localStorage.getItem(KeycloakStorageKeys.refresh));
                        if (ssoData?.access_token) {
                            const response = await refreshToken({ sso_access: ssoData.access_token });
                            if (response.data) {
                                setToken(response.data.access);
                            }
                            resolve(response.data);
                        } else {
                            logOut();
                            reject();
                        }
                    } catch (error) {
                        logOut();
                        reject(error);
                    } finally {
                        setIsRefreshing(false);
                        refreshTokenPromise = null; // Сбрасываем промис после завершения
                    }
                });
            } else {
                console.log("Refresh in progress.");
            }

            return refreshTokenPromise;
        }

        return null;
    }, [isRefreshing, logOut, setIsRefreshing, setToken]);


    return {
        isAuth,
        token,
        onAuth,
        logOut,
        isRefreshing,
        onRefreshToken,
    }
}
