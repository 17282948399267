"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvancedCalculationSetSchema = exports.AdvancedCalculationSchema = exports.AdvanceCalculationSetStatuses = void 0;
const zod_1 = require("zod");
var AdvanceCalculationSetStatuses;
(function (AdvanceCalculationSetStatuses) {
    AdvanceCalculationSetStatuses["CREATED"] = "created";
    AdvanceCalculationSetStatuses["PROCESSING"] = "processing";
    AdvanceCalculationSetStatuses["FAILED"] = "failed";
    AdvanceCalculationSetStatuses["PAID"] = "paid";
    AdvanceCalculationSetStatuses["EXPIRED"] = "expired";
    AdvanceCalculationSetStatuses["READY_TO_PAID"] = "ready_to_paid";
})(AdvanceCalculationSetStatuses = exports.AdvanceCalculationSetStatuses || (exports.AdvanceCalculationSetStatuses = {}));
exports.AdvancedCalculationSchema = zod_1.z.object({
    id: zod_1.z.number(),
    user_id: zod_1.z.number(),
    status: zod_1.z.string(),
    original_amount: zod_1.z.string().nullable(),
    final_amount: zod_1.z.string().nullable(),
    fee: zod_1.z.string().nullable(),
    currency_code: zod_1.z.string(),
    comment: zod_1.z.string().nullable(),
});
exports.AdvancedCalculationSetSchema = zod_1.z.object({
    id: zod_1.z.number().int(),
    user_id: zod_1.z.number().int(),
    status: zod_1.z.nativeEnum(AdvanceCalculationSetStatuses),
    advance_calculations: zod_1.z.array(exports.AdvancedCalculationSchema),
    comment: zod_1.z.string().nullable()
});
