import {Checkbox, Input} from "@design-system/ui-kit";
import {useTranslation} from "react-i18next";

import {useTier2} from "../../../../../domain/verification/providers";
import {VerificationNavigation} from "../../navigation/navigation";
import {i18n} from "../../../../../i18n";

import {QuestionnaireHeader} from "./header";

export const VerificationTier2Q1 = () => {
    const {t} = useTranslation()

    const {
        q1answersVariants,
        q1Values,
        setQ1Values,
        q1OtherValue,
        onNext,
        onBack,
        setQ1OtherValue,
        isQ1Verified,
    } = useTier2();

    return <>
        <div>
            <QuestionnaireHeader/>
        </div>

        <div>
            <h2 className={'text-header-06 font-heading font-bold text-label-secondary mb-6'}>
                {t('1. What type of traffic do you have?')}
            </h2>
            <div className={'grid grid-cols-2 gap-size-0.5'}>
                {Object.keys(q1answersVariants).map((key) => {
                    return <div className={`rounded border border-interface-sixth p-size-0.5 cursor-pointer ${q1Values?.[key] ? 'bg-background-tertiary' : '' }`}
                                key={key}
                                onClick={() => {
                                    setQ1Values((prev) => {
                                        return {...prev, [key]: prev?.[key] ? !prev?.[key] : true}
                                    })
                                }}>
                        <Checkbox onChange={() => null}
                                  checked={q1Values?.[key]}
                                  className={'pointer-events-none'}
                                  label={<span className={'text-body-02 text-label-secondary'}>
                                      {/* t('verification.tier2.answersStep1Variants.cashback') */}
                                      {/* t('verification.tier2.answersStep1Variants.coupon') */}
                                      {/* t('verification.tier2.answersStep1Variants.context') */}
                                      {/* t('verification.tier2.answersStep1Variants.brand_bidding') */}
                                      {/* t('verification.tier2.answersStep1Variants.doorway') */}
                                      {/* t('verification.tier2.answersStep1Variants.incentive') */}
                                      {/* t('verification.tier2.answersStep1Variants.social_media_target') */}
                                      {/* t('verification.tier2.answersStep1Variants.toolbar') */}
                                      {/* t('verification.tier2.answersStep1Variants.pop_up') */}
                                      {/* t('verification.tier2.answersStep1Variants.other') */}
                            {i18n.t('verification.tier2.answersStep1Variants.' + key, {defaultValue: q1answersVariants[key]})}
                        </span>}/>
                    </div>
                })}
            </div>
            <div className={'mt-size-0.5'}>
                <Input value={q1OtherValue}
                       nativePlaceholder
                       onChange={(v) => setQ1OtherValue(v)}
                       disabled={!q1Values?.other}
                       placeholder={t('verification.tier2.YourVariant')}/>
            </div>
        </div>
        <VerificationNavigation onNext={onNext}
                                onBack={onBack}
                                isDisabledBack={true}
                                isDisabledNext={!isQ1Verified}/>
    </>
}
