import {Button, ButtonSizes} from "@design-system/ui-kit";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

import {Paths} from "../../../../core/router";

export const EmptyList = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()


    return <div className={'flex h-full flex-col py-8 items-center justify-center'}>
        <img src={'/images/capybara-coffee.svg'} alt={''} className={'mb-size-3'}/>
        <div className={'text-header-03 font-extrabold mb-size-2'}>{t("You don't have any payments yet")}</div>
        <div className={'text-label-secondary mb-size-3'}>{t("Start withdrawing funds profitably. Go to the section and order your first withdrawal from Capyfast!")}</div>
        <div>
            <Button size={ButtonSizes.xl} onClick={() => navigate(Paths.HOME)}>{t('Go to payments')}</Button>
        </div>
    </div>
}
