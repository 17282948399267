import {ReactComponent as EmailIcon} from "assets/svg/interface-icons/email.svg";
import {Button, ButtonThemes} from "@design-system/ui-kit";
import clipboardCopy from 'clipboard-copy';
import { useTranslation } from "react-i18next";

import {Modal} from "../../../molecules/modal";

export const HelpModal = ({isShow, onClose}: {isShow: boolean, onClose: () => unknown}) => {
    const {t} = useTranslation()

    return <Modal isShow={isShow} maxWidth={'25rem'} onClose={onClose}>
        <div className={'text-body-02 mb-size-0.5 text-label-secondary'}>
            {t('If you have questions, we are right here:')}
        </div>
        <div
            className={'flex justify-between gap-2 items-center mt-size-0.5 border-s2 border-interface-seventh rounded-xl px-4 py-2 bg-background-primary'}>
            <div className={'flex items-center gap-2 text-label-quaternary'}>
                <EmailIcon/>
                support@capyfast.com
            </div>
            <Button theme={ButtonThemes.ghostFill} onClick={() => clipboardCopy('support@capyfast.com')}>{t('Copy')}</Button>
        </div>
    </Modal>
}
