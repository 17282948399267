import {getCommonApiClient} from "../client";
import {AdvancedCalculationSchemaResponseSchema} from "@capyfast/contracts";

export const getAdvancedCalculation = ({payout_type}: {payout_type: 'auto' | 'manual'}) => {
    return getCommonApiClient().get<AdvancedCalculationSchemaResponseSchema>('/api/internal/v1/finance/advance-calculation/', {
        params: {payout_type}
    })
}


export const syncAdvancedCalculation = ({payout_type}: {payout_type: 'auto' | 'manual'}) => {
    return getCommonApiClient().post<AdvancedCalculationSchemaResponseSchema>('/api/internal/v1/finance/advance-calculation/initiate/', {payout_type})
}
