import {InputsSizes, PopoverVerticalPositions, Select} from "@design-system/ui-kit";
import {i18n} from "i18n";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {LOCALES} from "../../../core/constants/i18n";
import {useLocalization} from "../../../domain/i18n/providers/i18n";


export const getSelectItemByValue = (
    options: any[],
    selectedValue: any | any[] | null,
): any[] => {
    let selectedOptions: Omit<any, 'selected'>[];
    if (Array.isArray(selectedValue)) {
        selectedOptions = options.filter((option) => selectedValue.includes(option.value));
    } else {
        const selected = options.find(({value}) => value === selectedValue);
        selectedOptions = selected ? [selected] : [];
    }

    return selectedOptions.map((option) => ({...option, selected: true}));
};


export const getSingleValueFromSelectItem = (value: any[]): any => {
    return value[0]?.value;
};


export const Footer = () => {
    const {t} = useTranslation();
    const {lang, changeLang} = useLocalization();

    const footerLinks = [
        {text: i18n.t("CapyFast"), path: 'https://capyfast.com', analyticsPath: "/capyfast"},
        {text: i18n.t("Privacy Policy"), path: 'https://capyfast.com/privacy-policy/', analyticsPath: "/privacy-policy"},
    ];

    const languages = [
        {node: t('language.EN'), value: LOCALES.EN,},
        {node: t('language.ES'), value: LOCALES.ES,},
        {node: t('language.PL'), value: LOCALES.PL,},
        {node: t('language.PT'), value: LOCALES.PT,},
        {node: t('language.RU'), value: LOCALES.RU,},
        {node: t('language.UA'), value: LOCALES.UK,},
    ];

    return (
        <div className={'flex justify-between items-center gap-4 text-sm text-label-tertiary pb-8'}>
            <div className={'flex gap-8'}>
                {footerLinks.map((link, index) => (
                    <Link target="_blank" key={index} to={link.path}>
                        {link.text}
                    </Link>
                ))}
            </div>
            <div className={'flex justify-end grow'}>
                <Select
                    onChange={(value) => changeLang(getSingleValueFromSelectItem(value))}
                    dropProps={{
                        position: PopoverVerticalPositions.top,
                    }}
                    inputProps={{
                        size: InputsSizes.L,
                    }}
                    selected={getSelectItemByValue(languages, lang)}
                    items={languages}
                />
            </div>
        </div>
    )

}
