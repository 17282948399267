import {ReactComponent as Icon} from "assets/svg/interface-icons/commission-label.svg";
import {ReactNode} from "react";
import classnames from "classnames";

type CommissionLabelProps = {
    isAuto?: boolean,
    className?: string,
    texts: {
        isAuto: string,
        isManual: string,
        saveMore: ReactNode
    }
}

export const CommissionLabel = ({isAuto, texts, className}: CommissionLabelProps) => {
    return <div
        className={classnames('relative flex py-size-0.5 px-size-3 rounded-xl items-center gap-size-0.5', {
            'text-label-secondary bg-background-success': !isAuto,
            'text-label-quaternary bg-label-seventh': isAuto
        }, className)}>
        <Icon/>
        <div className={'text-body-03 font-medium'}
             dangerouslySetInnerHTML={{__html: !isAuto ? texts.isAuto : texts.isManual}}/>
        {!isAuto && <div className={'absolute right-[15%] bottom-[115%] hidden md:flex items-start'}>
            <div className={'text-brand-primary text-body-03 font-medium'}>
                {texts.saveMore}
            </div>
            <svg className={'mt-3'} width="76" height="61" viewBox="0 0 76 61" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M72.1611 60.2926C71.9434 60.4625 71.6292 60.4237 71.4593 60.206L68.691 56.6583C68.5211 56.4406 68.5599 56.1264 68.7776 55.9565C68.9953 55.7866 69.3095 55.8254 69.4794 56.0431L71.9401 59.1967L75.0937 56.7359C75.3114 56.566 75.6256 56.6048 75.7954 56.8225C75.9653 57.0402 75.9265 57.3544 75.7088 57.5243L72.1611 60.2926ZM0.875876 1.45574C21.0662 -1.78098 40.4787 0.216764 54.0894 9.37112C67.7439 18.5549 75.4468 34.8625 72.3498 59.9597L71.3573 59.8372C74.4198 35.0202 66.8018 19.1264 53.5313 10.2009C40.217 1.24592 21.1005 -0.7737 1.03416 2.44313L0.875876 1.45574Z"
                    fill="#93B800"/>
            </svg>
        </div>}
    </div>
}
