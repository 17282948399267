import {Pagination} from "@design-system/ui-kit";
import {useRecoilState} from "recoil";
import {useTranslation} from 'react-i18next';

import {Card} from "../../atoms/card";
import {HistoryTable} from "../../organisms/@history/table/table";
import {DetailsModal} from "../../organisms/@history/details-modal/details-modal";
import {usePayoutHistoryQuery} from "../../../domain/payout/queries/history";
import {EmptyList} from "../../organisms/@history/empty/empty-list";
import {SelectedHistoryItem} from "../../../core/store";
import {ListSkeleton} from "../../organisms/@history/skeleton/skeleton";

export const History = () => {

    const {t} = useTranslation()

    const [selectedHistoryItem, setSelectedHistoryItem] = useRecoilState(SelectedHistoryItem)
    const {
        historyItems,
        total,
        limit,
        isLoading,
        isEmpty,
        isFetching,
        page,
        handleSetPage,
        setLimit,
    } = usePayoutHistoryQuery();


    return <Card className={'p-8 min-h-full'}>
        <div className={'mb-6'}>
            <div className={'mb-6 text-header-05 font-medium'}>{t('History')}</div>
            {isFetching && <ListSkeleton/>}
            {isEmpty && <EmptyList/>}
            {!isFetching && <HistoryTable items={historyItems} onSelectItem={(item) => setSelectedHistoryItem(item)}/>}
            <DetailsModal item={selectedHistoryItem} onClose={() => setSelectedHistoryItem(null)}/>
        </div>
        {!!total && <Pagination
            itemsCount={total}
            itemsPerPage={limit}
            onChange={(v) => {
                handleSetPage(v.pageNumber ?? 1)
                if(v.itemsPerPage) setLimit(v.itemsPerPage);
            }}
            hidePaginatorNavigate={true}
            pageNumber={page}
            perPageDropdownOptions={[10, 20, 30]}
            texts={{}}/>}
    </Card>
}
