import {Checkbox} from "@design-system/ui-kit";
import {useRecoilState} from "recoil";
import {Trans} from "react-i18next";

import {isAcceptTermsAndConditionsAtom} from "../../../../core/store";


export const AcceptTermsAndConditions = () => {
    const [accept, setAccept] = useRecoilState(isAcceptTermsAndConditionsAtom)
    return <div>
        <Checkbox onChange={setAccept}
                  checked={accept}
                  label={
                      <span>
                <Trans i18nKey={'payment.acceptTermsAndConditions'}
                       components={[<a href={'/CapyFast_Terms.pdf'}
                                       className={'underline'} target={'_blank'}
                                       rel="noreferrer"/>]}>
                    I accept the terms and conditions of the CapyFast
                </Trans>
                    </span>
                  }/>
    </div>
}
