import {ReactComponent as Separator} from "assets/svg/interface-icons/breadcrumb-separator.svg";
import {useNavigate} from "react-router-dom";

type BreadcrumbProps = {
    items: {
        name: string,
        link?: string,
    }[]
}

export const Breadcrumb = ({items}: BreadcrumbProps) => {
    const navigate = useNavigate();
    return <div className={'flex'}>
        {items.map((item, index) => {
            return <div key={item.name + index} className={'flex'}>
                <div className={`${!!item.link ? 'text-label-secondary cursor-pointer' : 'text-label-fifth'}`}
                    onClick={() => {
                    item.link && navigate(item.link)
                }}>{item.name}</div>
                {!!items[index + 1] && <Separator className={'w-6 text-label-fifth'}/>}
            </div>
        })}
    </div>
}
