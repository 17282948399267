import {memo} from "react";
import {Button, ButtonBorderRadius, ButtonSizes} from "@design-system/ui-kit";
import {formatAmount} from "@capyfast/utils";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

import {useGetStatisticsQuery} from "../../../../domain/statistics/queries/get-statistics";
import {useWalletBalances} from "../../../../domain/statistics/queries/wallet-balances";
import {CurrenciesSymbols} from "../../../../core/constants/currencies-symbols";
import {ENV} from "../../../../core/environment";

type BalanceProps = {
    text: string,
    balance: string,
    className?: string,
    isLoading?: boolean
}

const Balance = memo(({
                          text,
                          balance,
                          className,
                          isLoading
                      }: BalanceProps) => {
    return <div className={className}>
        <div className={'text-body-03 text-label-quaternary'}>{text}</div>
        <div
            className={classnames('font-heading font-extrabold text-header-06 text-label-secondary text-nowrap',
                {'rounded bg-background-fifth text-background-fifth animate-pulse text-nowrap': isLoading})}>{balance}</div>
    </div>
});

export const BalancesInfo = () => {
    const {t} = useTranslation()
    const {statisticData, isLoading} = useGetStatisticsQuery();
    const {walletsData, isLoading: isLoadingWallets} = useWalletBalances();

    return <div className={'bg-background-tertiary px-size-5 py-10 flex gap-size-3 items-center justify-between'}>
        <div className={'flex grow gap-size-3 justify-between pr-size-3'}>
            <Balance isLoading={isLoading} text={t('Total payouts')}
                     balance={`$ ${formatAmount(statisticData?.total_sum)}`}/>
            <Balance isLoading={isLoading} text={t('Saved time')} balance={t('balancesInfo.savedDays', {count: statisticData?.saved_time ?? 0})}/>
            <Balance text={t('balancesInfo.USDWallet')}
                     balance={`${CurrenciesSymbols['USD']} ${formatAmount(walletsData.USD)}`}
                     isLoading={isLoadingWallets}
                     className={'border-l-s2 border-interface-sixth pl-size-3'}/>
            <Balance text={t('EUR wallet')}
                     balance={`${CurrenciesSymbols['EUR']} ${formatAmount(walletsData.EUR)}`}
                     isLoading={isLoadingWallets}/>
            <Balance text={t('GBP wallet')}
                     balance={`${CurrenciesSymbols['GBP']} ${formatAmount(walletsData.GBP)}`}
                     isLoading={isLoadingWallets}/>
        </div>
        <div>
            <Button size={ButtonSizes.xl} target={'_blank'} href={ENV.WALLET_LINK} borderRadius={ButtonBorderRadius.l}>{t('Open Wallet')} →</Button>
        </div>
    </div>
}
