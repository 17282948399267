import {ReactComponent as HomeIcon} from "assets/svg/navigation/main.svg";
import {ReactComponent as PaymentIcon} from "assets/svg/navigation/payment.svg";
import {ReactComponent as WalletIcon} from "assets/svg/navigation/wallet.svg";
import {ReactComponent as SupportIcon} from "assets/svg/navigation/support.svg";
import {ReactComponent as HelpIcon} from "assets/svg/navigation/help.svg";

export const NavigationItems = {
    Home: <HomeIcon/>,
    Payment: <PaymentIcon/>,
    Wallet: <WalletIcon/>,
    Support: <SupportIcon/>,
    HelpIcon: <HelpIcon/>
}
