import {ReactComponent as ErrorIcon} from "assets/svg/actions/error.svg";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export const NoNewPayoutAlert = ({className}: {className?: string}) => {
    const {t} = useTranslation()

    return <div className={classnames('p-size-3 flex gap-size-0.5 bg-background-tertiary rounded-xl', className)}>
        <div>
            <ErrorIcon className={'w-7 text-label-danger'}/>
        </div>
        <div className={''}>
            <h3 className={'font-heading text-header-06 font-extrabold mb-[0.69] text-label-tertiary'}>{t("There's no new payout")}</h3>
            <p className={'text-label-tertiary text-body-02'}>
                {t("We'll show the amount here as soon as new payouts are available.")}
            </p>
        </div>
    </div>
}
