import {useQuery} from "@tanstack/react-query";
import {getVerificationTierState} from "@capyfast/api";
import {useRecoilValue} from "recoil";
import {TierLevelsSchema} from "@capyfast/contracts";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {isApiReadySelector} from "../../../core/store";

export const useGetTierStatusQuery = ({enabled, tier}: { enabled: boolean, tier: TierLevelsSchema | null }) => {
    const isReady = useRecoilValue(isApiReadySelector);
    const {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
    } = useQuery({
        queryKey: [QueriesKeys.GET_VERIFICATION_TIER_STATUS, tier],
        queryFn: () => {
            return tier && getVerificationTierState(tier)
                .then((response) => {
                    return response.data;
                })
        },
        enabled: isReady && enabled,
        refetchInterval: 10000
    })

    return {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
    }
}
