import {useMutation} from "@tanstack/react-query";
import {SubscribeNetwork} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useSubscribeNetwork = () => {
    const {
        mutate,
        isPending,
        isError,
        isSuccess,
        reset,
    } = useMutation({
        mutationKey: [MutationKeys.SUBSCRIBE_NETWORK],
        mutationFn: ({email, network_name}: Parameters<typeof SubscribeNetwork>[0]) => {
            return SubscribeNetwork({email, network_name});
        },
    })

    return {
        subscribe: mutate,
        isPending,
        isError,
        isSuccess,
        reset,
    }
}
