import {atom} from "recoil";
import {PassportSchema} from "@capyfast/contracts";

import {Screens} from "../constants";

export const PayoutStepAtom = atom<Screens>({
    key: 'PayoutStepAtom',
    // @ts-ignore
    default: Number(window.localStorage.getItem('screen') as Screens.INFO ?? Screens.INFO),
})


export const SelectedPassportTypeAtom = atom<PassportSchema['legal_personality'] | null>({
    key: 'SelectedPassportTypeAtom',
    default: null
})
