import {createContext, ReactNode, useContext} from "react";

import {usePayoutFlowService} from "../services/payout-flow";

const PayoutFlowContext = createContext<null | ReturnType<typeof usePayoutFlowService>>(null)

export const PayoutFlowProvider = ({children}: {children: ReactNode}) => {
    const service = usePayoutFlowService();

    return <PayoutFlowContext.Provider value={service}>
        {children}
    </PayoutFlowContext.Provider>
}

export const usePayoutFlow = () => {
    const context = useContext(PayoutFlowContext);
    if(!context) throw new Error('payout flow provider not set');

    return context;
}
