import {useAutoPayoutsConfigQuery} from "../queries/get-auto-payouts-config";

export const useAutoPayoutsConfig = ({enabled = true}: {enabled: boolean }) => {
    const {
        data,
        isError,
        isPending,
        isSuccess,
        isLoading,
        refetch: refetchAutoPayoutConfig,
    } = useAutoPayoutsConfigQuery({enabled});


    return {
        autoPayoutsConfig: data,
        isError,
        isPending,
        isSuccess,
        isLoading,
        refetchAutoPayoutConfig,
    }
}
