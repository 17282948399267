import {formatAmount} from "@capyfast/utils";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import {useGetStatisticsQuery} from "../../../../domain/statistics/queries/get-statistics";

export const PlatformStatistics = () => {
    const {t} = useTranslation()

    const {statisticData, isLoading} = useGetStatisticsQuery();

    return <div className={'bg-background-secondary rounded flex gap-size-3 p-6'}>
        <div className={'space-y-[0.44rem]'}>
            <div className={'text-body-03 text-label-tertiary'}>{t('Total payouts')}</div>
            <div className={
                classnames('text-header-05 font-heading font-extrabold text-label-secondary',
                    {'rounded bg-background-fifth text-background-fifth animate-pulse text-nowrap': isLoading})
            }>$ {formatAmount(statisticData?.total_sum ?? 0)}</div>
        </div>
        <div className={'space-y-[0.44rem]'}>
            <div className={'text-body-03 text-label-tertiary'}>{t('Saved with auto payments')}</div>
            <div className={classnames('text-header-05 font-heading font-extrabold text-brand-primary',
                {'rounded bg-background-fifth text-background-fifth animate-pulse text-nowrap': isLoading})}>$ {formatAmount(statisticData?.saved_with_auto ?? 0)}</div>
        </div>
        <div className={'space-y-[0.44rem]'}>
            <div className={'text-body-03 text-label-tertiary'}>{t('Saved time')}</div>
            <div className={classnames('text-header-05 font-heading font-extrabold text-brand-primary',
                {'rounded bg-background-fifth text-background-fifth animate-pulse text-nowrap': isLoading})}>{statisticData?.saved_time ?? '0'} {t('days')} </div>
        </div>
    </div>
}
