"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationStatuses = void 0;
var VerificationStatuses;
(function (VerificationStatuses) {
    VerificationStatuses["CREATED"] = "created";
    VerificationStatuses["SUMSUB_INITIATED"] = "sumsub_initiated";
    VerificationStatuses["PROCESSING"] = "processing";
    VerificationStatuses["FAILED"] = "failed";
    VerificationStatuses["SUCCESS"] = "success";
    VerificationStatuses["NEED_ACTION"] = "need_action";
})(VerificationStatuses = exports.VerificationStatuses || (exports.VerificationStatuses = {}));
