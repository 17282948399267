import {useRecoilValue} from "recoil";

import {Card} from "../../atoms/card/card";
import {PlatformCard} from "../../organisms/@platforms/card/card";
import {AvailablePlatformsAtom, ComingSoonPlatformsAtom} from "../../../core/store";
import {BalancesInfo} from "../../organisms/@payout/balances-info";
import { useTranslation } from 'react-i18next';

import Style from './home.module.scss'


export const HomePage = () => {

    const {t} = useTranslation()

    const availablePlatforms = useRecoilValue(AvailablePlatformsAtom)
    const comingSoonPlatforms = useRecoilValue(ComingSoonPlatformsAtom)

    return <div className={`${Style.wrapper} min-h-full`}>
        <Card className={'overflow-hidden min-h-full flex flex-col gap-size-3'}>
            <BalancesInfo/>
            <div className={'p-size-5 flex flex-col gap-size-3'}>
                <div>
                    <div className={'mb-size-3 text-header-04 font-heading text-label-secondary font-extrabold'}>
                        {t('Available platforms')}
                    </div>
                    <div className={'grid grid-cols-3 gap-size-3'}>
                        {availablePlatforms.map((platform) => {
                            return <PlatformCard key={platform.id} platform={platform}/>
                        })}
                    </div>
                </div>
                <div>
                    <div className={'mb-size-3 text-header-04 font-heading text-label-quaternary font-extrabold'}>
                        {t('Coming Soon')}
                    </div>
                    <div className={'grid grid-cols-3 gap-size-3'}>
                        {comingSoonPlatforms.map((platform) => {
                            return <PlatformCard key={platform.id} platform={platform}/>
                        })}
                    </div>
                </div>
            </div>
        </Card>
    </div>
}
