import {useRecoilState, useRecoilValue} from "recoil";
import {useCallback, useState} from "react";

import {isDisableAllowedSelector, isOpenDisabledModalSelector} from "../../../core/store";
import {useDisableAutoPayoutsMutation} from "../mutations/disable-auto-payouts";

export const useDisableAutoPayoutFlow = () => {
    const [isOpen, setIsOpen] = useRecoilState(isOpenDisabledModalSelector);
    const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false)
    const {
        disableAutoPayouts,
        isError,
        isPending,
        isSuccess,
        reset,
    } = useDisableAutoPayoutsMutation();

    const isDisableAllowed = useRecoilValue(isDisableAllowedSelector);

    const onStartDisabled = useCallback(() => {
        setIsShowConfirmation(true)
    }, [])

    const onCancelConfirmation = useCallback(() => {
        setIsShowConfirmation(false);
        setIsOpen(false);
    }, [setIsOpen])

    const onDisable = useCallback(() => {
        setIsShowConfirmation(false);
        disableAutoPayouts();
    }, [disableAutoPayouts])

    // useEffect(() => {
    //     if (isSuccess) {
    //         setIsOpen(false);
    //         reset();
    //     }
    // }, [isSuccess]);

    return {
        isOpen,
        isDisableAllowed,
        isError,
        isPending,
        isSuccess,
        isShowConfirmation,
        setIsOpen,
        setIsShowConfirmation,
        onCancelConfirmation,
        onStartDisabled,
        onDisable,
    }
}
