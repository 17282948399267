import numeral from 'numeral';
if (numeral.locales['pay'] === undefined) {
    numeral.register('locale', 'pay', {
        delimiters: {
            thousands: ' ',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: function (number) {
            return number === 1 ? 'er' : 'ème';
        },
        currency: { symbol: '$' },
    });
}
numeral.locale('pay');
export var formatNumber = function (number, format) {
    if (format === void 0) { format = '0,0'; }
    return numeral(Number(number)).format(format);
};
