import {useMutation} from "@tanstack/react-query";
import {createTier2Verification} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useCreateTier2 = () => {
    const {
        data,
        mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    } = useMutation({
        mutationKey:[MutationKeys.CREATE_TIER_2] ,
        mutationFn: () => createTier2Verification().then(response => {
            return response.data
        })
    })

    return {
        id: data?.id ?? null,
        mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    }
}
