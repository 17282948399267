"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutStatuses = void 0;
var PayoutStatuses;
(function (PayoutStatuses) {
    PayoutStatuses["processing"] = "Processing";
    PayoutStatuses["system_checking"] = "Processing";
    PayoutStatuses["failed"] = "Failed";
    PayoutStatuses["paid"] = "Paid";
    PayoutStatuses["declined"] = "Declined";
})(PayoutStatuses = exports.PayoutStatuses || (exports.PayoutStatuses = {}));
