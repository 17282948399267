import {RadioButton} from "@design-system/ui-kit";

type ListSelectProps = {
    items: {value: string, label: string}[],
    value: ListSelectProps["items"][0]["value"],
    onSelectItem: (item: ListSelectProps["items"][0]["value"] ) => unknown
}
export const ListSelect = ({items, value, onSelectItem}: ListSelectProps) => {
    return <div className={'space-y-[0.62rem]'}>
        {items.map((item) => {
            return <div key={item.value}
                        onClick={() => onSelectItem(item.value)}
                        className={'p-5 border rounded cursor-pointer hover:border-brand-primary'}>
                <RadioButton onSelect={(v) => !!v && onSelectItem(v as string)}
                             value={item.value}
                             currentValue={value}
                             label={item.label}/>
            </div>
        })}
    </div>
}
