import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";

import {isAutoPayoutsActiveSelector, PlatformSchema} from "../../../../core/store";
import {Paths} from "../../../../core/router";

type PlatformCardProps = {
    platform: PlatformSchema,
}

export const PlatformCard = ({platform}: PlatformCardProps) => {
    const {t} = useTranslation()

    const navigate = useNavigate();
    const isAutoPayoutEnabled = useRecoilValue(isAutoPayoutsActiveSelector)

    const cardClasses = isAutoPayoutEnabled && platform.active
        ? 'bg-background-success border-background-success'
        : 'bg-background-tertiary border-interface-seventh';

    return <div className={`${cardClasses} flex flex-col rounded-[1rem] border-s2`}>
        <div className={'grow bg-background-primary rounded-[1rem] flex flex-col'}>
            <div className={'border-b border-interface-seventh p-6'}>
                {platform.logo_url && <img className={'h-[2.75rem]'} src={platform.logo_url} alt={platform.title}/>}
            </div>
            <div className={'p-6 grow'}>
                <div className={'text-label-secondary text-header-07 font-extrabold font-heading mb-[0.62rem]'}>
                    {t(platform.title)}
                </div>
                <div className={'text-label-quaternary text-body-03'}>
                    {t(platform.short_description)}
                </div>
            </div>
            <div className={'p-6 pt-0'}>
                {platform.active && <>
                    {isAutoPayoutEnabled
                        ? <Button size={ButtonSizes.xl}
                                  onClick={() => navigate(Paths.PLATFORMS + platform.key)}
                                  theme={ButtonThemes.interfaceOnsurface}>
                            {t('Platform profile')}
                        </Button>
                        : <Button size={ButtonSizes.xl}
                                  onClick={() => navigate(Paths.PLATFORMS + platform.key)}>
                             {t('Get Auto Payout')}
                        </Button>}
                </>}
                {!platform.active &&
                    <Button size={ButtonSizes.xl}
                            onClick={() => navigate(Paths.PLATFORMS + platform.key)}
                            theme={ButtonThemes.interfaceOnsurface}>
                        {t('Platform profile')}
                    </Button>}

            </div>
        </div>

        <div className={classnames('px-6 py-2 text-body-03', {
            'text-label-fifth': !platform.active,
            'text-label-tertiary cursor-pointer': !isAutoPayoutEnabled && platform.active,
            'text-label-success': isAutoPayoutEnabled && platform.active
        })}>
            {platform.active
                ? isAutoPayoutEnabled ? t('Auto activate') : t('Auto payout off')
                : t('Coming soon')}

        </div>
    </div>
}
