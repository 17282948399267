export var truncate = function (string, len, sep) {
    if (sep === void 0) { sep = '...'; }
    if (typeof string !== 'string') {
        return '';
    }
    if (string.length <= len) {
        return string;
    }
    sep = sep || '...';
    var sepLen = sep.length, charsToShow = len - sepLen, frontChars = Math.ceil(charsToShow / 2), backChars = Math.floor(charsToShow / 2);
    return string.substr(0, frontChars)
        + sep
        + string.substr(string.length - backChars);
};
