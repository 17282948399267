import {get} from "lodash";

import {CLIENT_ID, OIC_ENDPOINT} from "../constants";

export const ssoRefreshToken = (refreshToken: string | null) => {
    if(!refreshToken) return Promise.reject();

    const headers = new Headers();
    headers.append('Content-type','application/x-www-form-urlencoded; charset=UTF-8');
    headers.append('Accept', 'application/json');

    const body = {
        "grant_type": "refresh_token",
        "client_id": CLIENT_ID,
        "refresh_token": refreshToken
    }

    let formBody = [];
    for (let property in body) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(get(body, property));
        formBody.push(encodedKey + "=" + encodedValue);
    }

    const init: RequestInit = {
        method: 'POST',
        headers: headers,
        body: formBody.join("&"),
        mode: 'cors'
    };

    return fetch(OIC_ENDPOINT + '/token', init)
        .then(response => response.json())
}