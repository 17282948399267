export const Data = [
  {
    id: 1,
    title: 'platforms.admitad.title',
    key: 'admitad',
    url: 'https://admitad.com',
    short_description: 'platforms.admitad.short_description',
    description: 'platforms.admitad.description',
    logo_url: '/images/platforms/admitad.svg',
    active: true,
  },
  {
    id: 3,
    title: 'platforms.takeads.title',
    key: 'takeads',
    url: 'https://takeads.com/',
    short_description: 'platforms.takeads.short_description',
    description: 'platforms.takeads.description',
    logo_url: '/images/platforms/takeads.svg',
    active: false,
  },
  {
    id: 4,
    title: 'platforms.cj.title',
    key: 'cj',
    url: 'https://www.cj.com/',
    short_description: 'platforms.cj.short_description',
    description: 'platforms.cj.description',
    logo_url: '/images/platforms/cj.svg',
    active: false,
  },
  {
    id: 5,
    title: 'platforms.awin.title',
    key: 'awin',
    url: 'https://awin.com/us',
    short_description: 'platforms.awin.short_description',
    description: 'platforms.awin.description',
    logo_url: '/images/platforms/awin.svg',
    active: false,
  },
  {
    id: 6,
    title: 'platforms.impact.title',
    key: 'impact',
    url: 'https://impact.com/',
    short_description: 'platforms.impact.short_description',
    description: 'platforms.impact.description',
    logo_url: '/images/platforms/impact.svg',
    active: false,
  },
  {
    id: 7,
    title: 'platforms.rakuten.title',
    key: 'rakuten',
    url: 'https://rakutenadvertising.com/fr-fr/',
    short_description: 'platforms.rakuten.short_description',
    description: 'platforms.rakuten.description',
    logo_url: '/images/platforms/rakuten.svg',
    active: false,
  },
  {
    id: 8,
    title: 'platforms.tradedoubler.title',
    key: 'tradedoubler',
    url: 'https://tradedoubler.com/',
    short_description: 'platforms.tradedoubler.short_description',
    description: 'platforms.tradedoubler.description',
    logo_url: '/images/platforms/tradedoubler.svg',
    active: false,
  },
  {
    id: 9,
    title: 'platforms.webgains.title',
    key: 'webgains',
    url: 'https://webgains.com/public/en/',
    short_description: 'platforms.webgains.short_description',
    description: 'platforms.webgains.description',
    logo_url: '/images/platforms/webgains.svg',
    active: false,
  },
  {
    id: 10,
    title: 'platforms.belboon.title',
    key: 'belboon',
    url: 'https://belboon.com/',
    short_description: 'platforms.belboon.short_description',
    description: 'platforms.belboon.description',
    logo_url: '/images/platforms/belboon.svg',
    active: false,
  },
  {
    id: 11,
    title: 'platforms.daisycon.title',
    key: 'daisycon',
    url: 'https://daisycon.com/',
    short_description: 'platforms.daisycon.short_description',
    description: 'platforms.daisycon.description',
    logo_url: '/images/platforms/daisycon.svg',
    active: false,
  },
  {
    id: 12,
    title: 'platforms.aliexpress.title',
    key: 'aliexpress',
    url: 'https://portals.aliexpress.com/affiportals/web/portals.htm#/home',
    short_description: 'platforms.aliexpress.short_description',
    description: 'platforms.aliexpress.description',
    logo_url: '/images/platforms/aliexpress.svg',
    active: false,
  },
  {
    id: 13,
    title: 'platforms.alibaba-group.title',
    key: 'alibaba-group',
    url: 'https://ads.alibaba.com',
    short_description: 'platforms.alibaba-group.short_description',
    description: 'platforms.alibaba-group.description',
    logo_url: '/images/platforms/alibaba.png',
    active: false,
  },
  {
    id: 14,
    title: 'platforms.amazon.title',
    key: 'amazon',
    url: 'https://affiliate-program.amazon.com/',
    short_description: 'platforms.amazon.short_description',
    description: 'platforms.amazon.description',
    logo_url: '/images/platforms/amazon.svg',
    active: false,
  },
  {
    id: 15,
    title: 'platforms.ebay.title',
    key: 'ebay',
    url: 'https://partnernetwork.ebay.com',
    short_description: 'platforms.ebay.short_description',
    description: 'platforms.ebay.description',
    logo_url: '/images/platforms/ebay.svg',
    active: false,
  },
  {
    id: 16,
    title: 'platforms.lazada.title',
    key: 'lazada',
    url: 'https://lazada.com/en/',
    short_description: 'platforms.lazada.short_description',
    description: 'platforms.lazada.description',
    logo_url: '/images/platforms/lazada.svg',
    active: false,
  },
  {
    id: 17,
    title: 'platforms.shopee.title',
    key: 'shopee',
    url: 'https://shopee.com/',
    short_description: 'platforms.shopee.short_description',
    description: 'platforms.shopee.description',
    logo_url: '/images/platforms/shopee.svg',
    active: false,
  },
]
