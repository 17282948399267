import {ReactNode} from "react";

const variants = {
    success: 'text-label-success bg-background-success',
    secondary: 'text-label-tertiary bg-background-secondary',
    danger: 'text-label-danger bg-background-danger'
};

type StatusLabelProps = {
    children?: ReactNode,
    variant?: keyof typeof variants
}
export const StatusLabel = ({children, variant = 'success'}: StatusLabelProps) => {
    return <span className={`inline-block py-0.5 px-2 rounded ${variants[variant]}`}>{children}</span>
}
