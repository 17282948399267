import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {useCallback} from "react";

import {
    answersStep1VariantsAtom,
    isQ1VerifiedSelector,
    isQ2VerifiedSelector,
    isQ3VerifiedSelector,
    networkVariantsAtom,
    q1OtherValueAtom,
    q1PrepareDataSelector,
    q1ValueAtom,
    q2OtherValueAtom,
    q2PrepareDataSelector,
    q2ValueAtom,
    q3PrepareDataSelector,
    q3ValueAtom,
    q3ValuesSchema,
    QuestionnaireSteps,
    tier2StepActiveSelector,
    tier2StepsAtom
} from "../../../core/store";
import {useCreateTier2} from "../mutations/create-tier2";
import {useCreateQuestionnaire} from "../mutations/create-questionnaire";

export const useTier2Service = () => {
    const [step, setStep] = useRecoilState(tier2StepActiveSelector);
    const resetSteps = useResetRecoilState(tier2StepActiveSelector);
    const steps = useRecoilValue(tier2StepsAtom);
    /****/
    const q1answersVariants = useRecoilValue(answersStep1VariantsAtom);
    const [q1Values, setQ1Values] = useRecoilState(q1ValueAtom);
    const [q1OtherValue, setQ1OtherValue] = useRecoilState(q1OtherValueAtom);
    /****/
    const networksVariants = useRecoilValue(networkVariantsAtom);
    const [q2Values, setQ2Values] = useRecoilState(q2ValueAtom);
    const [q2OtherValue, setQ2OtherValue] = useRecoilState(q2OtherValueAtom);
    /****/
    const [q3Values, setQ3Values] = useRecoilState(q3ValueAtom);
    /****/
    const isQ1Verified = useRecoilValue(isQ1VerifiedSelector)
    const isQ2Verified = useRecoilValue(isQ2VerifiedSelector)
    const isQ3Verified = useRecoilValue(isQ3VerifiedSelector);
    /****/
    const q1Data = useRecoilValue(q1PrepareDataSelector);
    const q2Data = useRecoilValue(q2PrepareDataSelector);
    const q3Data = useRecoilValue(q3PrepareDataSelector);

    const {mutateAsync: create, isSuccess} = useCreateTier2();
    const {mutateAsync: createQuestionnaire, isSuccess: isSuccessCreateQuestionnaire} = useCreateQuestionnaire();



    const onSubmit = useCallback(() => {
        try{
            q3ValuesSchema.parse(q3Values)
            create().then((info) => {
                createQuestionnaire({
                    user_verification: info.id,
                    traffic_type: q1Data,
                    other_networks: q2Data,
                    admitad_share: q3Data,
                }).then()
            })
        }catch (e){
            console.error(e);
        }

    }, [create, createQuestionnaire, q1Data, q2Data, q3Data])

    const onNext = useCallback(() => {
        switch (step.title){
            case QuestionnaireSteps.Q1:
            default:
                return setStep(steps[1]);
            case QuestionnaireSteps.Q2:
                return setStep(steps[2]);
            case QuestionnaireSteps.Q3:
                return onSubmit();
        }
    }, [onSubmit, setStep, step, steps])

    const onBack = useCallback(() => {
        switch (step.title){
            case QuestionnaireSteps.Q1:
            default:
                return null;
            case QuestionnaireSteps.Q2:
                return setStep(steps[0]);
            case QuestionnaireSteps.Q3:
                return setStep(steps[1]);
        }
    }, [setStep, step, steps])


    return {
        steps,
        step,
        setStep,
        onNext,
        onBack,
        q1answersVariants,
        q1Values,
        q1OtherValue,
        setQ1OtherValue,
        setQ1Values,
        isQ1Verified,
        networksVariants,
        q2Values,
        setQ2Values,
        q2OtherValue,
        setQ2OtherValue,
        isQ2Verified,
        q3Values,
        setQ3Values,
        isQ3Verified,
        isComplete: isSuccess && isSuccessCreateQuestionnaire,
    }
}
