import {getCommonApiClient} from "../client";
import {UserConfigSchemaType, WalletBalancesSchemaType} from "@capyfast/contracts";

export function getUserConfig() {
    return getCommonApiClient().get<UserConfigSchemaType>('/api/internal/v1/user/config/')
}

export function signDocuments(document_ids: number[]) {
    return getCommonApiClient().post('/api/internal/v1/legal-document/signature/sign/', {document_ids})
}

export function getUserWalletBalances () {
    return getCommonApiClient().get<WalletBalancesSchemaType>('/api/internal/v1/user/balances/')
}
