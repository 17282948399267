import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useTranslation} from "react-i18next";

type VerificationNavigationProps = {
    onBack: () => unknown,
    onNext: () => unknown,
    isDisabledBack?: boolean,
    isDisabledNext?: boolean,
}

export const VerificationNavigation = ({onBack, onNext, isDisabledNext, isDisabledBack}: VerificationNavigationProps) => {
    const {t} = useTranslation()

    return <div className={'flex justify-between items-center'}>
        <Button size={ButtonSizes.xl}
                onClick={onBack}
                disabled={isDisabledBack}
                theme={ButtonThemes.interfaceOnsurface}>
            {t('Back')}
        </Button>
        <Button size={ButtonSizes.xl}
                disabled={isDisabledNext}
                onClick={onNext}>
            {t('Next')}
        </Button>
    </div>
}
