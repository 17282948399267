export enum Screens {
    INFO,
    VERIFICATION_TIER1,
    VERIFICATION_TIER2,
    VERIFICATION_TIER3,
    PENDING,
    SUCCESS,
    SUCCESS_ENABLED_AUTO,
    FAILED
}
