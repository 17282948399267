import {useCallback, useEffect, useState} from "react";
import get from "lodash/get";
import {useNavigate, useSearchParams} from "react-router-dom";

import {generateCodeChallenge, generateCodeVerifier, generateState} from "../utils/pkce";
import {getAuthCode} from "../utils/get-auth-code";
import {SSOToken} from "../queryes";
import {KeycloakStorageKeys} from "../constants";

const getStorage = (key: string) => localStorage.getItem(key);
const getPKCEState = () => {
    return {
        codeState: getStorage(KeycloakStorageKeys.state),
        codeVerifier: getStorage(KeycloakStorageKeys.codeVerifier),
        codeChallenge: getStorage(KeycloakStorageKeys.codeChallenge)
    }
}

const removePKCEState = () => {
    [
        getStorage(KeycloakStorageKeys.state),
        getStorage(KeycloakStorageKeys.codeVerifier),
        getStorage(KeycloakStorageKeys.codeChallenge)
    ].forEach((key) => {
        key && localStorage.removeItem(key);
    })
}

export const usePkceSso = () => {
    const {codeState, codeVerifier, codeChallenge} = getPKCEState();
    const [ssoToken, setSsoToken] = useState<string | null>(null);
    const [ssoIdToken, setSsoIdToken] = useState<string | null>(null);
    const [ssoRefreshToken, setSsoRefreshToken] = useState<string | null>(null);
    const [locationParams] = useSearchParams();
    const navigate = useNavigate();


    const generateAuthData = useCallback(() => {
        if (!getStorage(KeycloakStorageKeys.state) && !getStorage(KeycloakStorageKeys.codeVerifier)) {
            Promise.resolve().then(() => {
                localStorage.setItem(KeycloakStorageKeys.state, generateState());
                localStorage.setItem(KeycloakStorageKeys.codeVerifier, generateCodeVerifier());
            }).then(() => {
                const verifier = getStorage(KeycloakStorageKeys.codeVerifier);
                verifier && generateCodeChallenge(verifier).then(value => {
                    localStorage.setItem(KeycloakStorageKeys.codeChallenge, value);
                    setTimeout(() => {
                        const urlParams = new URLSearchParams(window.location.search);
                        !urlParams.get('code') && getAuthCode(getStorage(KeycloakStorageKeys.state), getStorage(KeycloakStorageKeys.codeChallenge));
                    }, 750);
                })
            })
        } else {
            codeState && codeChallenge && getAuthCode(codeState, codeChallenge);
        }
    }, [codeChallenge, codeState])

    const startLogIn = useCallback(() => {
        generateAuthData();
    }, [generateAuthData]);

    useEffect(() => {
        const code = locationParams.get('code');
        const state = locationParams.get('state')
        const error = locationParams.get('error')
        if (code && state) {
            //locationParams.get('error_description')
            if (codeVerifier && !error) {

                SSOToken(code, codeVerifier).then(data => {
                    if (!data.error) {
                        setSsoToken(get(data, 'access_token'));
                        setSsoIdToken(get(data, 'id_token'));
                        setSsoRefreshToken(get(data, 'refresh_token', ''));
                        localStorage.setItem(KeycloakStorageKeys.token, get(data, 'access_token', ''));
                        localStorage.setItem(KeycloakStorageKeys.refresh, get(data, 'refresh_token', ''));
                        localStorage.setItem(KeycloakStorageKeys.idToken, get(data, 'id_token', ''));
                        removePKCEState();
                    } else {
                        // startLogIn();
                    }
                })
            } else navigate('/', {replace: true});
        }

    }, [codeVerifier, locationParams, navigate, startLogIn]);

    return {
        code: locationParams.get('code'),
        codeChallenge,
        codeVerifier,
        ssoToken,
        ssoIdToken,
        ssoRefreshToken,
        startLogIn,
    }

}
