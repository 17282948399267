import {formatAmount} from "@capyfast/utils";
import {ApproximateAmountsSchema} from "@capyfast/contracts";
import classnames from "classnames";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";

import {CurrenciesSymbols} from "../../../../core/constants/currencies-symbols";
import {isAutoPayoutSelector} from "../../../../core/store";
import {useGetStatisticsQuery} from "../../../../domain/statistics/queries/get-statistics";

type CalculationProps = {
    className?: string
    amounts: ApproximateAmountsSchema[],
    textClassName?: string,
    isLoading?: boolean
}


const LoadingSkeleton = ({className}: { className?: string }) => {
    const {t} = useTranslation()

    return <div className={classnames('flex gap-6 animate-pulse', className)}>
        <div className={'space-y-[0.38rem] flex flex-col'}>
            <div className={'text-body-03 text-label-secondary'}>{t('Payment amounts:')}</div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-2/3 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div className={'pt-1'}>
                <div className={`text-body-03 text-brand-primary `}>{t('Saved time')} </div>
                <div className={`text-brand-primary bg-brand-primary rounded text-header-03 font-heading font-bold`}>
                    {t('{{value}} day', {value: 56})}
                </div>
            </div>
        </div>
        <div className={'space-y-[0.38rem]'}>
            <div className={'text-body-03 text-label-quaternary'}>{t('Save with auto')}</div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-4/5 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-11/12 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
        </div>
    </div>
}

export const Calculation = ({amounts, className, textClassName = 'text-label-sixth', isLoading}: CalculationProps) => {
    const {t} = useTranslation()

    const isAutoPayout = useRecoilValue(isAutoPayoutSelector);
    const {statisticData, isLoading: isLoadingStatistics} = useGetStatisticsQuery();

    return <>
        {isLoading && <LoadingSkeleton className={className}/>}
        {!isLoading && <div className={classnames('flex gap-6', className)}>
            <div className={'space-y-[0.38rem]'}>
                <div className={'text-body-03 text-label-secondary'}>{t('Payment amounts:')}</div>
                {amounts.map((amount) => {
                    return <div key={amount.final_amount + amount.currency_code}
                                className={`text-label-secondary text-header-03 font-heading font-bold`}>
                        {CurrenciesSymbols[amount.currency_code as 'EUR'] || amount.currency_code}{' '}
                        {formatAmount(isAutoPayout
                            ? amount.final_amount
                            : amount.manual_final_amount)}
                    </div>
                })}
                <div className={'pt-1'}>
                    <div className={`text-body-03 text-brand-primary ${textClassName}`}>{t('Saved time')}</div>
                    {isLoadingStatistics
                        ? <div
                            className={`text-brand-primary bg-brand-primary rounded text-header-03 font-heading font-bold`}>
                            {t('{{value}} day', {value: 56})}
                        </div>
                        : <div className={`text-brand-primary text-header-03 font-heading font-bold ${textClassName}`}>
                            {statisticData?.saved_time_with_auto} {t('day')}
                        </div>
                    }
                </div>
            </div>
            <div className={'space-y-[0.38rem]'}>
                <div className={'text-body-03 text-label-quaternary'}>{t('Save with auto')}</div>
                {amounts.map((amount) => {
                    return <div key={amount.final_amount + amount.currency_code}
                                className={`${textClassName} text-header-03 font-heading font-bold`}>
                        {CurrenciesSymbols[amount.currency_code as 'EUR'] || amount.currency_code}{' '}
                        {formatAmount(isAutoPayout
                            ? Number(amount.final_amount) - Number(amount.manual_final_amount)
                            : '0')}
                    </div>
                })}
            </div>
        </div>
        }
    </>
}
