import {ReactComponent as SuccessIcon} from "assets/svg/interface-icons/success.svg";
import { useTranslation } from "react-i18next";

export const SuccessTier1 = () => {
    const {t} = useTranslation()

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}><SuccessIcon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>
            {t('Verification successful')}
        </div>
        <div className={'text-center text-body-01 text-label-tertiary max-w-[20.5rem]'}>
           {t(' We have started the withdrawal process. Your money will soon be transferred to your Wallet')}
        </div>
    </div>
}
