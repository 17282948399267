import {useRecoilValue} from "recoil";
import {Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {isApiReadySelector, isAuthSelector, isUserBlockedAtom} from "../store";
import {AuthPage} from "../../components/@pages/auth";
import {ServiceNotAvailable} from "../../components/organisms/@errors/service-not-available/service-not-available";
import {Card} from "../../components/atoms/card";

export const PrivateRouter = () => {
    const isAuth = useRecoilValue(isAuthSelector)
    const isReady = useRecoilValue(isApiReadySelector);
    const isUserBlocked = useRecoilValue(isUserBlockedAtom);
    const {t} = useTranslation();


    if(isUserBlocked){
        return <Card className={'overflow-hidden min-h-[calc(100dvh-68px-100px)] py-size-3 flex flex-col gap-size-3 items-center justify-center'}>
            <ServiceNotAvailable title={t('error.userBlock.title')} description={t('error.userBlock.description')}/>
        </Card>
    }

    if(isAuth && isReady && !isUserBlocked){
        return <Outlet/>
    }

    return <AuthPage/>

}
