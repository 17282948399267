import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useRecoilValue} from "recoil";
import { Trans, useTranslation } from "react-i18next";

import {Modal} from "../../../molecules/modal";
import {ConditionsOfAutoPayout} from "../conditions-of-auto-payout";
import {AcceptTermsAndConditions} from "../accept-terms-and-conditions/accept-terms-and-conditions";
import {useEnableAutoPayoutFlow} from "../../../../domain/auto-payout/services/enable";
import {disabledAutoPayoutAfterSelector} from "../../../../core/store";

export const ReactivateAutoPayoutModal = () => {
    const {t} = useTranslation()

    const {
        isOpen,
        isAccept,
        isError,
        isPending,
        onEnable,
        setIsOpen,
    } = useEnableAutoPayoutFlow();

    const daysToDisabled = useRecoilValue(disabledAutoPayoutAfterSelector)

    return <>
        <Modal isShow={isOpen} maxWidth={'37.5rem'} onClose={() => setIsOpen(false)}>
            <div className={'space-y-size-3'}>
                <div>
                    <div className={'text-label-secondary text-header-05 font-bold font-heading mb-size-0.5'}>
                        {t('Request for Auto Advance Payout')}
                    </div>
                    <div className={'text-label-tertiary text-body-02'}>
                        {t('Getting Auto Advanced Payout will let you withdraw money easier, faster and with lower commission.')}
                    </div>
                </div>

                <ConditionsOfAutoPayout hideContent/>

                <AcceptTermsAndConditions/>

                <div className={'flex justify-between items-center'}>
                    <div className={'flex items-center gap-4'}>
                        <Button size={ButtonSizes.xl}
                                onClick={() => onEnable()}
                                disabled={!isAccept}
                                loading={isPending}>
                            {t('Reactivate Auto-payout')}
                        </Button>
                        <div className="text-label-quaternary text-body-03 max-w-[10rem]">
                        <Trans i18nKey="autoPayoutDisabledNotice" values={{ daysToDisabled }}>
                            Auto-payout will be <b className="text-label-danger font-bold">turned off</b> in {{daysToDisabled}} days.
                        </Trans>
                        </div>
                    </div>
                    <div>
                        <Button theme={ButtonThemes.interfaceOnsurface}
                                onClick={() => setIsOpen(false)}
                                size={ButtonSizes.xl}>
                            {t('Back')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}
