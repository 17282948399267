import {getCommonApiClient} from "../client";
import {PassportSchema} from "@capyfast/contracts";
import {AxiosResponse} from "axios";

export function getPassport() {
    return getCommonApiClient().get<PassportSchema>('/api/internal/v1/passport/user_info/');
}

export function createPassport(legal_personality: PassportSchema['legal_personality']) {
    return getCommonApiClient().post<
        PassportSchema,
        AxiosResponse<PassportSchema>,
        { legal_personality: PassportSchema['legal_personality'] }
    >('/api/internal/v1/passport/user_info/', {legal_personality})
}

export function getSumsubToken({payout_type}: { payout_type: 'auto' | 'manual' }) {
    return getCommonApiClient().get<{ token: string }>(
        "/api/internal/v1/passport/generate_token/",
        {params: {payout_type}}
    );
}
