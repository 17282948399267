import {AxiosResponse} from "axios";
import {getCommonApiClient} from "../client";
import {
    AdvancedCalculationSchemaResponseSchema,
    HistoryResponseSchema,
    PaginationParams,
    StatisticSchemaType,
    PayoutTypes,
} from "@capyfast/contracts";

export function createPayout (payout_type: PayoutTypes) {
    return getCommonApiClient().post<AdvancedCalculationSchemaResponseSchema>('/api/internal/v1/finance/advance-calculation-set/initiate/', {
        payout_type
    })
}

export function getPayoutsHistory ({limit, offset}: PaginationParams) {
    return getCommonApiClient()
        .get<HistoryResponseSchema, AxiosResponse<HistoryResponseSchema>, HistoryResponseSchema>('/api/internal/v1/finance/payout/', {
        params: {limit, offset}
    })
}

export function getAdvanceCalculationSet(id: string | number) {
    return getCommonApiClient()
        .get<AdvancedCalculationSchemaResponseSchema>(`/api/internal/v1/finance/advance-calculation-set/${id}/`)
}

export function getPayoutStatistics (){
    return getCommonApiClient()
        .get<StatisticSchemaType, AxiosResponse<StatisticSchemaType>>('/api/internal/v1/finance/statistic/')
}
