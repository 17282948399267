import {useSumsubGetTokenQuery} from "domain/sumsub/queries/token";

import SumsubWebSdk from "@sumsub/websdk-react";
import {MessageHandler, SnsWebSdkBaseConfig} from "@sumsub/websdk";
import SumsubWebSdkProps from "@sumsub/websdk-react/types/src/SumsubWebSdkProps";
import {Button, ButtonSizes, ButtonThemes, Loader} from "@design-system/ui-kit";
import {useTranslation} from "react-i18next";


type WebSdkProps = {
    onFinish?: () => unknown,
    onBack?: () => unknown
}
export const WebSdk = ({onFinish, onBack}: WebSdkProps) => {
    const {t} = useTranslation();
    const {
        token,
        isFetching,
        refetch,
    } = useSumsubGetTokenQuery({enabled: true});


    const accessTokenExpirationHandler = async () => {
        let v = await refetch();
        return v?.data ?? ''
    }
    const config: SnsWebSdkBaseConfig = {
        theme: 'light',
        email: undefined,
        phone: undefined
    }

    const options: SumsubWebSdkProps["options"] = {};
    const messageHandler: MessageHandler = (message: any, payload) => {
        if (message?.type === 'READY') {

        } else if (message?.type === 'COMPLETE') {
            onFinish?.()
        }
        if (message === 'idCheck.onReady') {

        } else if (message === "idCheck.onComplete") {
            onFinish?.()
        }
    }
    const errorHandler = () => {
    }

    return <div className={'min-h-full flex flex-col justify-between'}>
        {isFetching
            ? <div className={'flex justify-center items-center min-h-32'}><Loader/></div>
            : <SumsubWebSdk
                accessToken={token ?? ''}
                expirationHandler={accessTokenExpirationHandler}
                config={config}
                options={options}
                onMessage={messageHandler}
                onError={errorHandler}
            />
        }
        <div className={'flex justify-between items-center mt-size-3'}>
            <Button theme={ButtonThemes.interfaceOnsurface}
                    size={ButtonSizes.xl}
                    onClick={onBack}>
                {t('Back')}
            </Button>
            <Button theme={ButtonThemes.interfaceOnsurface}
                    size={ButtonSizes.xl}
                    onClick={() => {
                        window.open('https://forms.gle/y2nG2j8TNwVbBESK8', '_blank')
                    }}>
                {t('verification.tier1.noCountry')}
            </Button>
        </div>
    </div>
}
