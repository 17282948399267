import {CSSProperties, FC, memo, ReactNode, ReactPortal, useEffect, useMemo} from "react";
import ReactDOM from "react-dom";
import classnames from 'classnames';

import ModalStyle from './modal.module.scss'

type ModalFC = FC<{
    isShow?: boolean,
    className?: string,
    onClose?: () => unknown,
    isDialog?: boolean,
    adaptive?: boolean
    children: ReactNode | ReactPortal | boolean | null | undefined
    maxWidth?: number | string
    isMobile?: boolean
    footer?: ReactNode
}>

const modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
    const container = document.createElement('div');
    container.id = 'modal-root';
    document.body.append(container);
}
export const Modal: ModalFC = memo(({
                                        children,
                                        isShow,
                                        isDialog,
                                        className,
                                        onClose,
                                        maxWidth,
                                        adaptive,
                                        isMobile,
                                        footer,
                                    }) => {

    const modalRoot = document.getElementById('modal-root');
    const appRoot = document.getElementById('body');

    const computeStyles = useMemo(() => {
        const styles: CSSProperties = {};
        if (typeof maxWidth === 'number') styles.maxWidth = maxWidth / 16 + 'rem'
        if (typeof maxWidth === 'string') styles.maxWidth = maxWidth;
        return styles
    }, [maxWidth])

    useEffect(() => {
        if (appRoot && isShow) appRoot.style.overflow = 'hidden';
        else if (appRoot) appRoot.style.overflow = 'auto';
        return () => {
            if (appRoot) appRoot.style.overflow = 'auto'
        };
    }, [appRoot, isShow])

    if (!modalRoot) {
        console.warn('Container with id "modal-root" was not found. Displaying modal windows is not possible!');
        return null;
    }

    if (!isShow) return null;

    return ReactDOM.createPortal(<div className={ModalStyle.overflow}>
        <div className={classnames(ModalStyle.wrapper, {[ModalStyle.adaptive]: adaptive})} onClick={onClose}>
            <div className={classnames(ModalStyle.modal, {
                [ModalStyle.dialog]: isDialog,
                [ModalStyle.mobile]: isMobile
            }, className)}
                 style={computeStyles}
                 onClick={(e) => {
                     e.stopPropagation()
                     //e.preventDefault()
                 }}>
                {!!onClose && <div className={ModalStyle.close} onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                        <path stroke="#C8C8C8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                              d="m8 8 8 8M16 8l-8 8"/>
                    </svg>
                </div>}
                <div className={classnames(ModalStyle.content, {[ModalStyle.withoutFooter]: !footer})}>
                    {children}
                </div>
                {footer && <div className={ModalStyle.footer}>
                    {footer}
                </div>}
            </div>

        </div>
    </div>, modalRoot as Element)
});

export {ModalStyle};
