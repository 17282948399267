import {useRecoilValue} from "recoil";
import {
    helpNavigationItemsAtom,
    mainNavigationItemsAtom,
    secondaryNavigationItemsAtom
} from "core/store/navigation.store";
import {ReactComponent as LogoutIcon} from "assets/svg/navigation/logout.svg";

import {useAppAuth} from "../../../core/app/app-auth";

import {AsideItem} from "./item";
import {AsideItemType} from './aside.types'

import Style from './aside.module.scss'

export const Aside = () => {
    const mainItems = useRecoilValue(mainNavigationItemsAtom);
    const secondaryItems = useRecoilValue(secondaryNavigationItemsAtom);
    const helpItems = useRecoilValue(helpNavigationItemsAtom);

    const {logOut} = useAppAuth();
    const logoutItem: AsideItemType = {
        key: 'logout',
        icon: <LogoutIcon/>,
        title: '',
        isActive: false,
    };

    return <aside className={`${Style.aside} bg-white rounded-2xl shadow-2xl`}>
        <nav className={Style.navigation}>
            {mainItems.map((item) => (
                <AsideItem key={item.key}
                           item={item}/>
            ))}
            <div className={Style.divider}></div>
            {secondaryItems.map((item) => (
                <AsideItem key={item.key}
                           item={item}/>
            ))}
        </nav>
        <nav className={`${Style.footer} mt-8`}>
            {helpItems.map((item) => (
                <AsideItem key={item.key}
                           item={item}/>
            ))}
            <AsideItem className={'!text-label-danger'} item={logoutItem} onPress={logOut}/>
        </nav>
    </aside>
}
