import {ENV} from 'core/environment'

export const CALLBACK_PATH = '/';
export const ROOT_URL = ENV.CLIENT_URL;
export const OIC_ENDPOINT = `${ENV.SSO_URL}/auth/realms/${ENV.SSO_REALM}/protocol/openid-connect`;
export const CLIENT_ID = ENV.SSO_CLIENT_ID;

export enum KeycloakStorageKeys {
    state = '9ed39e2ea931586b6a985a6942ef573e',
    codeVerifier = 'a96c001907391a3680c4edb46b169ce0',
    codeChallenge = '05af52b15fecc312d6b86a00647f4fe8',
    authCode = '02140ee7f69d2e3ffeec7a7498972990',
    returnedState = '7815696ecbf1c96e6894b779456d330e',
    token = '58e4d47591727ee7cb9c0587a8142f79',
    refresh = '45f89728adb0ec34e9c162b47910cbdf',
    idToken = '45f89728adb0ec34e9c1sdss333jskgs',
}
