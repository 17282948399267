import {Button, Loader, Text, TextAndIconColors, TextVariants} from "@design-system/ui-kit";
import React, {useEffect, useState} from "react";
import {getToken} from "@capyfast/api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';

import {usePkceSso} from "../../../core/keycloak";
import {useAppAuth} from "../../../core/app/app-auth";
import {Card} from "../../atoms/card";
import {Paths} from "../../../core/router";

export const AuthPage = () => {
    const {isAuth, onAuth, logOut, token} = useAppAuth();
    const {startLogIn, ssoToken, code} = usePkceSso();
    const [isError, setIsError] = useState<boolean>(false);
    const [status, setStatus] = useState<number>(200);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if (!isAuth && !code) {
                startLogIn();
            }
        }, 500);
    }, [isAuth, code, startLogIn])

    useEffect(() => {
        ssoToken && !isLoading && getToken(ssoToken)
            .then((response) => {
                onAuth(response.data.access, response.data.refresh);
                navigate(Paths.HOME, {replace: true})
            })
            .catch((e) => {
                setStatus(e.response.status)
                setIsError(true);
            }).finally(() => {
                setIsLoading(false)
            })
    }, [isLoading, navigate, onAuth, ssoToken])

    const { t } = useTranslation()

    return <Card className={'overflow-hidden min-h-[calc(100dvh-68px-100px)] flex flex-col gap-size-3 items-center justify-center'}>
            {!isError
                ? <div className={'text-body-02 text-label-primary'} color={TextAndIconColors.labelSecondary}>
                    <Loader/>
                        {token ? t('Authorization, please wait') : t('Loading, please wait')}
            </div>

                : <div style={{display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
                    {status === 403
                        ? <div>accessForbidden</div>
                        : <div>ServiceNotAvailableIcon</div>
                    }
                    <Text variant={TextVariants.header5} style={{marginBottom: '1rem'}}>
                        {status === 403 && t('Access forbidden')}
                        {status === 401 && t(`Authorisation Error`)}
                        {status >= 500 && `${status} Error`}
                    </Text>
                    <Text variant={TextVariants.body2} style={{marginBottom: '1rem', textAlign: 'center'}}>
                        {status === 401 && t('YourAccountIsNotVerifiedOrBlocked')}
                        {status === 403 && t('YouHaveEncounteredThisError')}
                        {status >= 500 && t('TheServerEncounteredAnInternal',`The server encountered an internal error or misconfiguration and was unable to complete
                            your request. Please to reload the page.`)}
                    </Text>
                    {[403, 401].indexOf(status) !== -1 && <Button onClick={logOut}
                        // variant={ButtonVariant.outlined}
                        // theme={ButtonThemes.labelsSecondary}
                    >
                        {t('Log in with another account')}
                    </Button>}
                    {status >= 500 && <Button onClick={() => window.location.reload()}
                        // variant={ButtonVariant.outlined}
                        // theme={ButtonThemes.labelsSecondary}
                    >
                        {t('Reload')}
                    </Button>}
                </div>}
        </Card>
}

