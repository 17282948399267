import i18n from "i18next";
import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {LOCALES} from "../../../core/constants/i18n";

const getInitialLang = () => {
    try {
        const savedLang = localStorage.getItem('language');
        return savedLang && Object.values(LOCALES).includes(savedLang as LOCALES) ? savedLang : 'en';
    } catch (error) {
        console.error("Error accessing localStorage:", error);
        return 'en';
    }
};
export const useLocalizationService = () => {
    const [lang, setLang] = useState<string>(getInitialLang());

    const [searchParams, setSearchParams] = useSearchParams();

    const changeLang = useCallback((lang: `${LOCALES}`) => {
        if(Object.values(LOCALES).includes(lang as LOCALES)){
            setLang(lang)
        }
    }, [])

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang])

    useEffect(() => {
        if(Object.values(LOCALES).includes(lang as LOCALES)){
            localStorage.setItem('language', lang);
            i18n.changeLanguage(lang);
        }
    }, [lang]);

    useEffect(() => {
        let lang = (searchParams.get("lang") ?? '').toLowerCase();
        if(lang && Object.values(LOCALES).includes(lang as LOCALES)) {
            if(lang === 'ua') lang = 'uk';
            changeLang(lang as `${LOCALES}`);
        }
    }, [changeLang, searchParams]);

    return {
        lang,
        changeLang
    }
}
