import {useQuery} from "@tanstack/react-query";
import {useRecoilValue} from "recoil";
import {getPassport} from "@capyfast/api";

import {isApiReadySelector} from "../../../core/store";
import {QueriesKeys} from "../../../core/constants/queries-keys";

export const usePassportQuery = ({enabled}: {enabled: boolean}) => {
    const isReady = useRecoilValue(isApiReadySelector)
    const {
        data,
        isLoading,
        isFetching,
        isStale,
        refetch,
        isError,
    } = useQuery({
        queryKey: [QueriesKeys.PASSPORT_USER_INFO],
        queryFn: () => getPassport().then(response => {
            if(response.data.hasOwnProperty('id')) return response.data
            else return Promise.reject({})
        }).catch(() => {
            return Promise.reject({})
        }),
        enabled: isReady,
        refetchInterval: false,
        retry: 1,
        refetchOnMount: true,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })

    return {
        passport: data,
        isLoading,
        isFetching,
        isStale,
        refetch,
        isError,
    }
}
