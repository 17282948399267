import {useState} from "react";
import {Button, ButtonThemes} from "@design-system/ui-kit";
import {ReactComponent as EmailIcon} from "assets/svg/interface-icons/email.svg";
import clipboardCopy from "clipboard-copy";
import {useTranslation} from 'react-i18next';

import {Card} from "components/atoms/card";

import {Accordion} from "../../atoms/accordion";

import {useFaqContent} from "./contents";

export const Support = () => {
    const {t} = useTranslation()
    const [active, setActive] = useState<string | number | null>(1);
    const {content} = useFaqContent();

    return <Card className={'overflow-hidden min-h-full p-card-padding-block'}>
        <div className={'text-header-04 font-heading font-extrabold mb-size-3'}>{t('Help and Support')}</div>
        <div className={'flex flex-col lg:flex-row gap-6'}>
            <div className={'grow'}>
                <Accordion onChange={(v) => setActive(v)} items={content} activeKey={active}/>
            </div>
            <div className={'max-w-[400px]'}>
                <div className={'rounded-xl p-size-3 bg-background-secondary'}>
                    <div className={'text-body-02 text-label-secondary'}>
                        {t('support.email.description')}
                    </div>
                    <div
                        className={'flex justify-between gap-2 items-center mt-size-0.5 border rounded-xl px-4 py-2 bg-background-primary'}>
                        <div className={'flex items-center gap-2 text-label-quaternary'}>
                            <EmailIcon/>
                            support@capyfast.com
                        </div>
                        <Button theme={ButtonThemes.ghostFill} onClick={() => clipboardCopy('support@capyfast.com')}>{t('Copy')}</Button>
                    </div>
                </div>
            </div>
        </div>
    </Card>
}
