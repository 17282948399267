import {useCallback, useState} from "react";
import classnames from "classnames";
import {AnimatePresence, motion} from 'framer-motion';
import {ReactComponent as HelpIcon} from "assets/svg/navigation/help.svg";
import { useTranslation } from 'react-i18next';

import {Card} from "components/atoms/card"

import {Welcome} from "../../organisms/@onboarding/welcome";
import {Information} from "../../organisms/@onboarding/information";
import {StepsIndicator} from "../../molecules/steps-indicator";
import {Conditions} from "../../organisms/@onboarding/conditions";
import {Finish} from "../../organisms/@onboarding/finish";
import {HelpModal} from "../../organisms/@onboarding/help-modal/help-modal";


const IS_FIRST_VISIT_KEY = 'is_first_visit'
const isFirstVisit = window.localStorage.getItem(IS_FIRST_VISIT_KEY) !== 'no';

enum OnboardingScreens {
    WELCOME,
    INFO,
    TERMS,
    FINISH
}

export const Onboarding = () => {
    const {t} = useTranslation()
    const [screen, setScreen] = useState<OnboardingScreens>(isFirstVisit
        ? OnboardingScreens.WELCOME
        : OnboardingScreens.INFO);

    const [isShowHelp, setIsShowHelp] = useState<boolean>(false)

    const onStartOnboarding = useCallback(() => {
        setScreen(OnboardingScreens.INFO);
        window.localStorage.setItem(IS_FIRST_VISIT_KEY, 'no')
    }, []);

    return (
        <Card
            className={classnames('relative overflow-hidden min-h-[calc(100dvh-68px-100px)] flex flex-col gap-size-3 items-center  p-size-3', {
                'justify-center': screen === OnboardingScreens.WELCOME
            })}
        >
            {screen !== OnboardingScreens.WELCOME && (
                <div className={'w-full'}>
                    <StepsIndicator steps={[
                        {
                            title: t('Step 1 - Networks'),
                            isActive: screen === OnboardingScreens.INFO
                                || screen === OnboardingScreens.TERMS
                                || screen === OnboardingScreens.FINISH
                        },
                        {
                            title: t('Step 2 - Documents'),
                            isActive: screen === OnboardingScreens.TERMS
                                || screen === OnboardingScreens.FINISH
                        },
                        {title: t('Step 3 - Documents'), isActive: screen === OnboardingScreens.FINISH},
                    ]}/>
                </div>
            )}
            <AnimatePresence mode='wait'>
                {screen === OnboardingScreens.WELCOME && (
                    <motion.div
                        key="welcome"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <Welcome onStart={onStartOnboarding}/>
                    </motion.div>
                )}
                {screen === OnboardingScreens.INFO && (
                    <motion.div
                        key="info"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <Information onStart={() => setScreen(OnboardingScreens.TERMS)}/>
                    </motion.div>
                )}
                {screen === OnboardingScreens.TERMS && (
                    <motion.div
                        key="terms"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <Conditions
                            onSuccess={() => setScreen(OnboardingScreens.FINISH)}
                            onBack={() => setScreen(OnboardingScreens.INFO)}
                        />
                    </motion.div>
                )}
                {screen === OnboardingScreens.FINISH && (
                    <motion.div
                        key="finish"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <Finish/>
                    </motion.div>
                )}
            </AnimatePresence>
            <HelpModal isShow={isShowHelp} onClose={() => setIsShowHelp(false)}/>
            <div className={'absolute bottom-[1rem] left-[1rem] text-interface-quaternary'}
            onClick={() => {setIsShowHelp(true)}}>
                <HelpIcon className={'cursor-pointer'}/>
            </div>
        </Card>
    );
};
