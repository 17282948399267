import {ReactComponent as InfoIcon} from "assets/svg/interface-icons/info.svg";
import {HistoryCollectionSchema, HistoryItemSchema, PayoutStatuses} from "@capyfast/contracts";
import moment from 'moment'
import {formatAmount} from "@capyfast/utils";
import {useTranslation} from "react-i18next";

import {StatusLabel} from "../../../atoms/status-label";
import {getStatusVariant, typeKeys} from "../../../../domain/history/constants";

import Style from './table.module.scss'

type HistoryTableProps = {
    onSelectItem?: (v: HistoryItemSchema) => unknown,
    items: HistoryCollectionSchema
}

export const HistoryTable = ({items, onSelectItem }: HistoryTableProps) => {
    const {t} = useTranslation()

    if(!items.length) return null;
    return <div className={'rounded-xl border overflow-hidden'}>
        <div className={`${Style.item} !bg-interface-seventh  overflow-hidden text-label-secondary p-2 [&:not(:last-child)]:border-b hover:bg-interface-fifth/20`}>
            <div>{t('ID')}</div>
            <div>{t('Date of operation')}</div>
            <div>{t('Platform')}</div>
            <div>{t('Status')}</div>
            <div>{t('Type of operation')}</div>
            <div>{t('Amount')}</div>
            <div></div>
        </div>
        {items.map((item) => {
            return <div key={item.id}
                        onClick={() => onSelectItem?.(item)}
                        className={`${Style.item} cursor-pointer overflow-hidden text-label-secondary p-2 [&:not(:last-child)]:border-b hover:bg-interface-fifth/20`}>
                <div>{t('ID')} {item.id}</div>
                <div>{moment(item.created_at).format('DD.MM.YYYY')}</div>
                <div className={'underline'}>Admitad</div>
                <div>
                    <StatusLabel variant={getStatusVariant(item.status)}>
                        {/* t('payout.statuses.Processing') */}
                        {/* t('payout.statuses.Failed') */}
                        {/* t('payout.statuses.Paid') */}
                        {/* t('payout.statuses.Declined') */}
                        {t('payout.statuses.' + PayoutStatuses[item.status], {defaultValue: PayoutStatuses[item.status]})}
                    </StatusLabel>
                </div>
                {/* t('payout.operationType.Auto') */}
                {/* t('payout.operationType.One-time') */}
                <div>{t('payout.operationType.' + typeKeys[item.type], {defaultValue: typeKeys[item.type]})}</div>
                <div className={'font-medium'}>{formatAmount(item.final_amount)} {item.currency_code}</div>
                <div className={'flex justify-end'}><InfoIcon className={'w-4 h-4'}/></div>
            </div>
        })}
    </div>
}
