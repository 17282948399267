import {ReactComponent as MinAmountIcon} from "assets/svg/interface-icons/min-amount.svg";
import {ReactComponent as ClockIcon} from "assets/svg/interface-icons/clock.svg";
import { useTranslation } from "react-i18next";

type ConditionsOfAutoPayoutProps = {
    hideContent?: boolean
}
export const ConditionsOfAutoPayout = ({hideContent}: ConditionsOfAutoPayoutProps) => {
    const {t} = useTranslation()

    return <div className={'px-size-3 py-6 border-s3 rounded-xl border-interface-seventh bg-background-primary text-label-quaternary'}>
        {!hideContent && <>
            <div className={'text-brand-primary text-header-08 mb-size-0.5 font-extrabold font-heading'}>{t('Activate Auto Advance Payout.')}</div>
            <div className={'text-body-03 text-label-quaternary mb-6'}>
                {t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
            </div>
        </>}
        <div className={'grid grid-cols-2 gap-size-0.5 '}>
            <div className={'flex items-start gap-size-0.5 text-body-03'}>
                <MinAmountIcon className={'w-14 aspect-square'}/>
                <div>
                    {t('As soon as 10 USD will be accumulated, there will be a transfer to the wallet')}
                </div>
            </div>
            <div className={'flex items-start gap-size-0.5  text-body-03'}>
                <ClockIcon className={'w-11 aspect-square'}/>
                <div>
                    {t('You can disable it no sooner than 30 days after enabling it')}
                </div>
            </div>
        </div>
    </div>
}
