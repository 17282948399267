import {atom, selector} from "recoil";
import {AdvancedCalculationSchemaResponseSchema} from "@capyfast/contracts";

import {LocalStorageKeys} from "../constants/local-storage-keys";

import {sessionStorageEffect} from "./effects/persisted-session-storage";

export const isAutoPayoutEnabledAtom = atom<boolean>({
    key: 'isAutoPayoutEnabledAtom',
    default: true,
    effects: [sessionStorageEffect('isAutoPayoutEnabledAtom', true)]
})

export const isAcceptTermsAndConditionsAtom = atom<boolean>({
    key: 'isAcceptTermsAndConditionsAtom',
    default: false
})

export const isNoNewPayoutAtom = atom<boolean>({
    key: 'isNoNewPayoutAtom',
    default: false
})

export const activeAdvanceCalculationSetAtom = atom<null | AdvancedCalculationSchemaResponseSchema>({
    key: 'activeAdvanceCalculationSetAtom',
    default: null,
    effects: [
        sessionStorageEffect(LocalStorageKeys.ACTIVE_ADV_SET)
    ]
})

export const isPayoutInProcessSelector = selector({
    key: 'isPayoutInProcessSelector',
    get: ({get}) => {
        return !!get(activeAdvanceCalculationSetAtom)
    },
})

export const isAutoPayoutSelector = selector<boolean>({
    key: 'isAutoPayoutSelector',
    get: ({get}) => {
        return get(isAutoPayoutEnabledAtom)
    },
    set: ({get, set}, newValue) => {
        return set(isAutoPayoutEnabledAtom, newValue);
    }
})
