import {atomFamily, selectorFamily, useRecoilState, useRecoilValue} from 'recoil';
import {useLayoutEffect, useState} from "react";

// Определение атомов
const totalState = atomFamily<number, string>({
    key: 'totalState',
    default: 0,
});

const pageState = atomFamily<number, string>({
    key: 'pageState',
    default: 1,
});

const sizeState = atomFamily<number, string>({
    key: 'sizeState',
    default: 10,
});

// Определение селектора для totalPages
const totalPagesState = selectorFamily<number, string>({
    key: 'totalPagesState',
    get: (key) => ({get}) => {
        const total = get(totalState(key));
        const size = get(sizeState(key));
        return Math.ceil(total / size);
    },
});

// Определение селектора для offset
const offsetState = selectorFamily<number, string>({
    key: 'offsetState',
    get: (key) => ({get}) => {
        const page = get(pageState(key));
        const size = get(sizeState(key));
        return (page - 1) * size;
    },
});

type UsePaginationOptions = {
    defaultLimit?: number,
} | undefined
// Определение хука
export function usePagination(key: string, {defaultLimit}: UsePaginationOptions = {}) {
    const [isConfigured, setIsConfigured] = useState<boolean>(false)
    const [total, setTotal] = useRecoilState(totalState(key));
    const [page, setPage] = useRecoilState(pageState(key));
    const [limit, setLimit] = useRecoilState(sizeState(key));
    const totalPages = useRecoilValue(totalPagesState(key));
    const offset = useRecoilValue(offsetState(key));

    useLayoutEffect(() => {
        if(defaultLimit) setLimit(defaultLimit);
        setIsConfigured(true)
    }, [defaultLimit])

    // Обработчик установки total
    const handleSetTotal = (value: number) => {
        setTotal(value);
        const newTotalPages = Math.ceil(value / limit);
        if (page > newTotalPages) {
            setPage(newTotalPages);
        }
    }

    // Обработчик установки page
    const handleSetPage = (value: number) => {
        if (value >= 1 && value <= totalPages) {
            setPage(value);
        }
    }

    return {
        total,
        page,
        limit,
        totalPages,
        offset,
        setLimit,
        handleSetTotal,
        handleSetPage,
        isConfigured,
    };
}
