import {useQuery} from "@tanstack/react-query";
import {getApproximateAmount} from "@capyfast/api";
import {useRecoilState, useRecoilValue} from "recoil";
import {ApproximateAmountsResponseSchema} from "@capyfast/contracts";
import {useEffect} from "react";

import {isApiReadySelector, isNoNewPayoutAtom} from "../../../core/store";
import {QueriesKeys} from "../../../core/constants/queries-keys";

const placeholderData: ApproximateAmountsResponseSchema = {
    amounts: []
}
export const useApproximateAmounts = () => {
    const isReady = useRecoilValue(isApiReadySelector);
    const [, setNoNewPayouts] = useRecoilState(isNoNewPayoutAtom)
    const {
        data,
        isError,
        isPending,
        isSuccess,
        isLoading,
        isFetching,
        isPlaceholderData,
        refetch,
    } = useQuery({
        queryKey: [QueriesKeys.APPROXIMATE_AMOUNTS],
        queryFn: async () => {
            const response = await getApproximateAmount();
            return response.data;
        },
        refetchOnMount: true,
        refetchInterval: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
        staleTime: 10000,
        placeholderData,
        enabled: isReady,
    })

    useEffect(() => {
        if(!isSuccess || isFetching) {
            setNoNewPayouts(false);
        }
        else if(isError || !data?.amounts.length){
            setNoNewPayouts(true);
        }else{
            let acc = 0;
            for(let amount of data.amounts){
                acc += Number(amount.final_amount ?? 0)
            }
            if(acc === 0) setNoNewPayouts(true);
            else setNoNewPayouts(false);
        }

    }, [data, isError, isSuccess, setNoNewPayouts]);

    return {
        amounts: data?.amounts ?? placeholderData.amounts,
        refetchApproximateAmount: refetch,
        isError,
        isPending,
        isSuccess,
        isLoading,
        isFetching,
        isPlaceholderData
    }
}
