import {useQuery} from "@tanstack/react-query";
import {getVerificationState} from "@capyfast/api";
import {useRecoilValue} from "recoil";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {isApiReadySelector} from "../../../core/store";

export const useGetVerificationInformationQuery = () => {
    const isReady = useRecoilValue(isApiReadySelector);
    const {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
    } = useQuery({
        queryKey: [QueriesKeys.GET_VERIFICATION_STATE],
        queryFn: () => getVerificationState()
            .then((response) => response.data),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 10,
        enabled: isReady,
    })

    return {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError,
    }
}
