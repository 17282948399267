import {Button, ButtonSizes} from "@design-system/ui-kit";
import { useTranslation } from "react-i18next";

type WelcomeProps = {
    onStart: () => unknown,
}

export const Welcome = ({onStart}: WelcomeProps) => {
    const {t} = useTranslation()

    return <div className={'flex flex-col justify-center items-center h-full'}>
        <img src={'/images/capybara-welcome.svg'} alt={'Welcome image'}/>
        <div className={'flex flex-col justify-center items-center space-y-6 max-w-[37.5rem]'}>
            <div className={'text-header-01 font-heading font-extrabold text-center'}>{t('Welcome to CapyFast!')}</div>
            <div className={'text-body-01 text-label-tertiary text-center'}>{t("It only takes 3 steps to start using CapyFast, and won't take you more than 2 minutes.")}</div>
            <div>
                <Button onClick={onStart} size={ButtonSizes.xl}>{t('Start onboarding')}</Button>
            </div>
        </div>
    </div>
}
