import {getCommonApiClient} from "../client";

export function getToken(token: string) {
  return getCommonApiClient().post<{ access: string; refresh: string }>(
    "/api/internal/v1/login/",
    {token},
  );
}

export function refreshToken({ sso_access }: { sso_access: string }) {
  return getCommonApiClient().post<{access: string}>("/api/internal/v1/refresh_token/", {
    sso_access,
  });
}
