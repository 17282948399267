import {ReactNode} from "react";
import classnames from "classnames";

type CardProps = {
    children: ReactNode,
    className?: string
}

export const Card = ({children, className}: CardProps) => {
    return <div className={classnames('bg-white rounded-xl shadow-xl', className)}>
        {children}
    </div>
}
