import {createContext, ReactNode, useContext} from "react";

import {useLocalizationService} from "../services/localization";

const I18nContext = createContext<ReturnType<typeof useLocalizationService> | null>(null);
export const I18NProvider = ({children}: {children: ReactNode}) => {

    return <I18nContext.Provider value={useLocalizationService()}>{children}</I18nContext.Provider>
}

export const useLocalization = () => {
    const context = useContext(I18nContext);

    if(!context) throw new Error('useLocalization context is null')

    return context;
}
