import {useQuery} from "@tanstack/react-query";
import {getAdvanceCalculationSet} from "@capyfast/api";
import {useRecoilState, useRecoilValue} from "recoil";
import {AdvanceCalculationSetStatuses, AdvancedCalculationSchemaResponseSchema} from "@capyfast/contracts";
import {useCallback, useState} from "react";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {activeAdvanceCalculationSetAtom, isApiReadySelector} from "../../../core/store";

export const useCheckPayoutStatus = (id?: number | null) => {
    const [, setAdvanceCalculationSet] = useRecoilState(activeAdvanceCalculationSetAtom);
    const [successIds, setSuccessIds] = useState<Set<number>>(new Set());
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [isFailed, setIsFailed] = useState<boolean>(false);
    const isReady = useRecoilValue(isApiReadySelector);
    const {
        data,
        isStale,
        isFetching,
        isError,
        isLoading,
        isSuccess,
        isPending,
        isPlaceholderData,
    } = useQuery<AdvancedCalculationSchemaResponseSchema>({
        queryKey: [QueriesKeys.CHECK_PAYOUT_STATUS, id],
        queryFn: () => {
            return getAdvanceCalculationSet(id ?? 0).then(response => {
                const {data} = response;
                if (data?.id) {
                    if (data?.status !== AdvanceCalculationSetStatuses.PAID) {
                        setAdvanceCalculationSet(data);
                    } else setAdvanceCalculationSet(null);

                    switch (data.status) {
                        case AdvanceCalculationSetStatuses.PAID:
                            setIsPaid(true);
                            setIsFailed(false);
                            break;
                        case AdvanceCalculationSetStatuses.FAILED:
                        case AdvanceCalculationSetStatuses.EXPIRED:
                            setIsFailed(true);
                            setIsPaid(false);
                            break;
                        default:
                            setIsPaid(false);
                            setIsFailed(false);
                    }

                    if (data.status === AdvanceCalculationSetStatuses.PAID
                        || data.status === AdvanceCalculationSetStatuses.EXPIRED
                        || data.status === AdvanceCalculationSetStatuses.FAILED
                    ) {
                        // setAdvanceCalculationSet(null);
                        setSuccessIds((prev) => {
                            prev.add(data.id);
                            return prev;
                        })
                    }
                    return response.data
                } else {
                    throw new Error('Error check advance calculation set');
                }
            })
        },
        enabled: isReady && !!id && !successIds.has(id),
        refetchInterval: 5000
    });

    const resetCheckPayout = useCallback(() => {
        setIsPaid(false);
        setIsFailed(false)
    }, []);

    return {
        advanceCalculationSet: data,
        isFailed,
        isPaid,
        resetCheckPayout,
        status: data?.status,
        isStale,
        isFetching,
        isError,
        isLoading,
        isSuccess,
        isPending,
        isPlaceholderData,
    }
}
