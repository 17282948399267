import {useQuery} from "@tanstack/react-query";
import {getPayoutsHistory} from "@capyfast/api";
import {HistoryCollectionSchema} from "@capyfast/contracts";
import {useRecoilValue} from "recoil";

import {usePagination} from "../../../core/hooks/pagination";
import {isApiReadySelector} from "../../../core/store";

type UsePayoutHistoryQueryOptions = {
    defaultLimit?: number,
    paginationKey?: string,
} | undefined

export const usePayoutHistoryQuery = (options: UsePayoutHistoryQueryOptions = {}) => {
    const {
        defaultLimit,
        paginationKey = 'payout-history',
    }= options;

    const isReady = useRecoilValue(isApiReadySelector);

    const {
        page,
        offset,
        setLimit,
        limit,
        totalPages,
        total,
        handleSetTotal,
        handleSetPage,
        isConfigured,
    } = usePagination(paginationKey, {
        defaultLimit
    })

    const {
        data,
        isStale,
        isFetching,
        isError,
        isLoading,
        isSuccess,
        isPending,
        isPlaceholderData,
    } = useQuery({
        queryKey: ['payout-history', offset, limit],
        queryFn: async () => {
            let response = await getPayoutsHistory({offset, limit});
            if(response.data.count) handleSetTotal(response.data.count)
            return response.data.results;
        },
        enabled: isReady && isConfigured,
        placeholderData: []
    })

    return {
        historyItems: isError || !data?.length ? [] as HistoryCollectionSchema : data,
        isEmpty: !isFetching && !data?.length,
        isStale,
        isFetching,
        isError,
        isPending,
        isLoading,
        isSuccess,
        page,
        offset,
        setLimit,
        limit,
        totalPages,
        total,
        isPlaceholderData,
        handleSetTotal,
        handleSetPage
    }
}
