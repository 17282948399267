"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserConfigSchema = exports.OnboardingLegalDocuments = exports.instantPayoutUserType = exports.UserLegalDocumentsSchema = void 0;
const zod_1 = require("zod");
exports.UserLegalDocumentsSchema = zod_1.z.object({
    'expired_datetime': zod_1.z.string().nullable(),
    'file_url': zod_1.z.string().nullable(),
    'id': zod_1.z.number().int(),
    'is_signed': zod_1.z.boolean(),
    'is_required': zod_1.z.boolean(),
    'key': zod_1.z.string(),
    'upload_datetime': zod_1.z.string().datetime(),
    'version': zod_1.z.number()
});
var instantPayoutUserType;
(function (instantPayoutUserType) {
    instantPayoutUserType["AUTO"] = "auto";
    instantPayoutUserType["MANUAL"] = "manual";
})(instantPayoutUserType = exports.instantPayoutUserType || (exports.instantPayoutUserType = {}));
var OnboardingLegalDocuments;
(function (OnboardingLegalDocuments) {
    OnboardingLegalDocuments["CAPYFAST_PRIVACY"] = "capyfast_privacy";
    OnboardingLegalDocuments["CAPYFAST_TERMS"] = "capyfast_terms";
    OnboardingLegalDocuments["CAPYFAST_MARKETING"] = "capyfast_marketing";
})(OnboardingLegalDocuments = exports.OnboardingLegalDocuments || (exports.OnboardingLegalDocuments = {}));
exports.UserConfigSchema = zod_1.z.object({
    admitad_defective_pi_ration: zod_1.z.boolean(),
    admitad_min_registration: zod_1.z.boolean(),
    is_blacklist: zod_1.z.boolean(),
    onboarding_legal_documents: zod_1.z.array(exports.UserLegalDocumentsSchema),
    instant_payout_user_type: zod_1.z.nativeEnum(instantPayoutUserType).optional(),
    is_disabled_manual_payouts: zod_1.z.boolean().optional()
});
