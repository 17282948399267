import {Fragment} from "react";
import moment from "moment/moment";
import {formatAmount} from "@capyfast/utils";
import {PayoutStatuses} from '@capyfast/contracts'
import {useTranslation} from "react-i18next";

import {StatusLabel} from "../../../atoms/status-label";
import {usePayoutHistoryQuery} from "../../../../domain/payout/queries/history";
import {getStatusVariant, typeKeys} from "../../../../domain/history/constants";

import Style from './history-table.module.scss'


export const HistoryTable = () => {
    const {t} = useTranslation()

    const {
        historyItems,
    } = usePayoutHistoryQuery({
        paginationKey: 'short-history',
        defaultLimit: 5,
    });

    return <div className={`${Style.table} rounded border-s2 border-label-seventh text-body-03`}>
        <div className={'bg-label-seventh font-medium'}>{t('Date of operation')}</div>
        <div className={'bg-label-seventh font-medium'}>{t('Status')}</div>
        <div className={'bg-label-seventh font-medium'}>{t('Total amount')}</div>
        <div className={'bg-label-seventh font-medium'}>{t('Saved')}</div>
        {!historyItems.length && <div className={`${Style.emptyContent} flex flex-col justify-center items-center !p-12`}>
            <div className={'text-body-03 text-label-primary font-medium'}>{t('Your payment history will appear here soon.')}</div>
            <div className={'text-body-04 text-label-quaternary'}>{t("We can't wait to see how much you've saved ;")}</div>
        </div>}
        {historyItems.map((item) => {
           return <Fragment key={item.id}>
               <div className={'border-b border-label-seventh'}>{moment(item.created_at).format('DD.MM.YYYY')}</div>
               <div className={'border-b border-label-seventh'}>
                   <StatusLabel variant={getStatusVariant(item.status)}>{`${PayoutStatuses[item.status]} • ${typeKeys[item.type]}`}</StatusLabel>

               </div>
               <div className={'border-b border-label-seventh'}>{formatAmount(item.final_amount)} {item.currency_code}</div>
               <div className={'border-b border-label-seventh'}>{formatAmount(item.saved_with_auto)} {item.currency_code}</div>
           </Fragment>
        })}
    </div>
}
