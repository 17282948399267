import { formatNumber } from '../index';
export var formatAmount = function (balance) {
    if (isNaN(Number(balance))) {
        return '0,00';
    }
    if (typeof balance === 'number') {
        balance = String(balance);
    }
    var numberParts = formatNumber(balance, '0.00').split(',');
    var trunc = Number(numberParts[0]);
    var fractional = numberParts[1] ? numberParts[1] : '00';
    var formatFractional = ((fractional === null || fractional === void 0 ? void 0 : fractional.length) ? String(fractional) : "".concat(fractional, "0"));
    return "".concat(formatNumber(trunc), ",").concat(formatFractional);
};
