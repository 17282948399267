import {useQuery} from "@tanstack/react-query";
import {getPayoutStatistics} from "@capyfast/api";
import {StatisticSchema} from "@capyfast/contracts";
import {useRecoilValue} from "recoil";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {isApiReadySelector} from "../../../core/store";

export const useGetStatisticsQuery = () => {
    const isReady = useRecoilValue(isApiReadySelector)
    const {
        data,
        isSuccess,
        isError,
        isPending,
        isFetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [QueriesKeys.GET_PAYOUT_STATISTICS],
        queryFn: async () => {
            return  getPayoutStatistics()
                .then(response => StatisticSchema.parse(response.data))
                .catch(e => {
                    return e;
                });
        },
        enabled: isReady,
        refetchOnWindowFocus: false,
        retry: false,
    })

    return {
        statisticData: data,
        isSuccess,
        isError,
        isPending,
        isFetching,
        isLoading,
        refetch,
    }
}
