import {Button, ButtonSizes} from "@design-system/ui-kit";
import {ReactComponent as CalcIcon} from "assets/svg/onboarding/calc.svg";
import {ReactComponent as ListIcon} from "assets/svg/onboarding/chack-list.svg";
import {ReactComponent as MonayIcon} from "assets/svg/onboarding/money.svg";
import {ReactComponent as CogIcon} from "assets/svg/onboarding/cog.svg";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";


type InformationProps = {
    onStart: () => unknown,
};
export const Information = ({onStart}:InformationProps) => {
    const {t} = useTranslation()

    const cardClasses  = 'rounded-xl bg-background-tertiary p-size-3 flex flex-col text-center items-center gap-y-size-0.5'

    return <div className={'max-w-[37.5rem]'}>
        <div className={'space-y-6'}>
            <div className={'w-10 mx-auto aspect-square rounded-full border-s3 border-brand-primary text-brand-primary flex justify-center items-center font-heading text-[1.4rem]'}>
                1
            </div>
            <div className={'text-header-03 font-heading font-extrabold text-center '}>
                {t('CapyFast allows you to receive your rewards from partner networks earlier! The process is simple:')}
            </div>
            <div className={'grid grid-cols-3 gap-6'}>
                <div className={cardClasses}>
                    <CalcIcon className={'text-brand-primary'}/>
                    <Trans i18nKey="StepOne">
                        <div className={classnames('relative leading-none bg-background-quaternary border border-interface-fifth px-size-0.5 py-1 rounded-full text-label-fifth uppercase',
                        'after:content-[\'\'] after:absolute after:h-[1px] after:left-[100%] after:w-[216%]  after:top-[50%] after:bg-interface-fifth')}>
                            Step 1
                        </div>
                        We calculate how much you can get
					</Trans>
                </div>
                <div className={cardClasses}>
                    <ListIcon className={'text-brand-primary'}/>
                    <Trans i18nKey="StepTwo">
                        <div
                            className={classnames('relative leading-none bg-background-quaternary border border-interface-fifth px-size-0.5 py-1 rounded-full text-label-fifth uppercase',
                                'after:content-[\'\'] after:absolute after:h-[1px] after:left-[100%] after:w-[216%]  after:top-[50%] after:bg-interface-fifth')}>
                            Step 2
                        </div>
                        You agree with conditions
					</Trans>
                </div>
                <div className={cardClasses}>
                    <MonayIcon className={'text-brand-primary'}/>
                    <Trans i18nKey='StepThree'>
                        <div
                            className={classnames('relative leading-none bg-background-quaternary border border-interface-fifth px-size-0.5 py-1 rounded-full text-label-fifth uppercase')}>
                            Step 3
                        </div>
                        Money is ready for withdrawal instantly
					</Trans>
                </div>
            </div>
            <div className={'flex items-center'}>
                <div className={'grow h-[1px] bg-interface-fifth'}></div>
                <div className={'px-3 text-label-fifth text-body-02'}>{t('Or you may')}</div>
                <div className={'grow h-[1px] bg-interface-fifth'}></div>
            </div>
            <div className={'bg-background-success py-7 px-16 rounded-xl flex gap-6'}>
                <div>
                    <div className={'text-header-07 font-heading font-extrabold'}>{t('Set up ')}<span
                        className={'text-brand-primary'}>{t('Auto Instant Payouts')}</span>!
                    </div>
                    <div className={'mt-size-0.5'}>{t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
                    </div>
                </div>
                <CogIcon className={'text-brand-primary'}/>
            </div>
        </div>
        <div className={'flex justify-center mt-10'}>
            <Button size={ButtonSizes.xl} onClick={onStart}>
                {t('Okay, got it')}
            </Button>
        </div>
    </div>
}
