import {ReactComponent as Logo} from 'assets/svg/logo.svg'

import {Profile} from "../../molecules/profile";

import Style from './topmenu.module.scss'

export const Topmenu = () => {
    return <div className={Style.topmenu}>
        <div><Logo/></div>
        <Profile/>
    </div>
}
