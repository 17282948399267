import {atom, selector} from "recoil";

import {isNeedSignDocsAtom, isUserLoadedAtom} from "./user.store";

export const hideLayoutNavigationAtom = atom({
    key: 'hideLayoutNavigation',
    default: false,
});

export const hideLayoutNavigationSelector = selector({
    key: 'hideLayoutNavigationSelector',
    get: ({get}) => {
        return !get(isUserLoadedAtom)
            || get(isNeedSignDocsAtom)
            || get(hideLayoutNavigationAtom);
    }
})
