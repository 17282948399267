import {atom, selector} from "recoil";
import {decodeJWT} from "@capyfast/utils";

import {LocalStorageKeys} from "../constants/local-storage-keys";

import {localStorageEffect} from "./effects/persisted-local-storage";

export const authTokenAtom = atom<string|null>({
    key: 'authTokenAtom',
    default: null,
    effects: [
        localStorageEffect(LocalStorageKeys.TOKEN)
    ]
});

export const authRefreshTokenAtom = atom<string|null>({
    key: 'authRefreshTokenAtom',
    default: null,
    effects: [
        localStorageEffect(LocalStorageKeys.REFRESH_TOKEN)
    ]
});

export const isRefreshInProgressAtom = atom({
    key: 'isRefreshInProgressAtom',
    default: false,
})


export const isAuthSelector = selector({
    key: 'isAuthSelector',
    get: ({get}) => {
        return !!get(authTokenAtom);
    }
})

export const isInjectTokenApiAtom = atom<boolean>({
    key: 'isInjectTokenApiAtom',
    default: false,
})

export const isApiReadySelector = selector({
    key: 'isApiReadySelector',
    get: ({get}) => {
        return !!get(authTokenAtom) && get(isInjectTokenApiAtom) && !get(isRefreshInProgressAtom);
    }
})

export const JWTPayloadSelector = selector<{
    "token_type": "access",
    "exp": number,
    "iat": number,
    "jti": string,
    "user_id": number,
    "email": string,
    "sso_id": string
}>({
    key: 'JWTPayloadSelector',
    get: ({get}) => {
        return get(authTokenAtom) ? decodeJWT(get(authTokenAtom) ?? '') : {
            "token_type": "access",
            "exp": 0,
            "iat": 0,
            "jti": '',
            "user_id": 0,
            "email": '',
            "sso_id": ''
        };
    }
})
