import {getCommonApiClient} from "../client";
import {AxiosResponse} from "axios";
import {
    TierLevelsSchema,
    VerificationStateResponseSchema,
    VerificationTierStatusResponseSchema
} from "@capyfast/contracts";

export function getVerificationState() {
    return getCommonApiClient().get<
        VerificationStateResponseSchema,
        AxiosResponse<VerificationStateResponseSchema>
    >('/api/internal/v1/passport/verification_info/')
}


export function getVerificationTierState(tier: TierLevelsSchema) {
    return getCommonApiClient().get<
        VerificationTierStatusResponseSchema,
        AxiosResponse<VerificationTierStatusResponseSchema>
    >(`/api/internal/v1/passport/level/${tier}/`)
}

export function createTier2Verification() {
    return getCommonApiClient().post<VerificationTierStatusResponseSchema,
        AxiosResponse<VerificationTierStatusResponseSchema>>('/api/internal/v1/passport/user_verification/level_2/', {})
}


export function createTier3Verification() {
    return getCommonApiClient().post<VerificationTierStatusResponseSchema,
        AxiosResponse<VerificationTierStatusResponseSchema>>('/api/internal/v1/passport/user_verification/level_3/', {})
}


