import {useMutation} from "@tanstack/react-query";
import {createTier3Verification} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useCreateTier3 = () => {
    const {
        mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    } = useMutation({
        mutationKey:[MutationKeys.CREATE_TIER_3] ,
        mutationFn: () => createTier3Verification()
    })

    return {
        mutate,
        mutateAsync,
        isError,
        isPending,
        isSuccess,
        reset,
    }
}
