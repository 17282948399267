import {atom, selector} from "recoil";
import {AutoPayoutsResponseSchema} from "@capyfast/contracts";
import moment from "moment";

export const AutoPayoutConfigAtom = atom<AutoPayoutsResponseSchema>({
    key: 'AutoPayoutConfigAtom',
    default: {
        disable_at_datetime: null,
        updated_at: null,
        status: "disabled"
    },
})

export const isAutoPayoutsActiveSelector = selector({
    key: 'isAutoPayoutsEnabledSelector',
    get: ({get}) => {
        return get(AutoPayoutConfigAtom)?.status === 'enabled'
    }
})


const isOpenDisabledModalAtom = atom({
    key: 'isOpenDisabledModalAtom',
    default: false,
})

const isOpenEnableModalAtom = atom({
    key: 'isOpenEnableModalAtom',
    default: false,
})

export const isOpenDisabledModalSelector = selector<boolean>({
    key: 'isOpenDisabledModalSelector',
    get: ({get}) => {
        return get(isAutoPayoutsActiveSelector) && get(isOpenDisabledModalAtom)
    },
    set: ({set, get}, newValue) => {
        set(isOpenDisabledModalAtom, newValue)
    }
})

export const isOpenEnabledModalSelector = selector<boolean>({
    key: 'isOpenEnabledModalSelector',
    get: ({get}) => {
        return get(isOpenEnableModalAtom)
    },
    set: ({set, get}, newValue) => {
        set(isOpenEnableModalAtom, newValue)
    }
})

export const isShowSuccessEnabledScreenAtom = atom<boolean>({
    key: 'isShowSuccessEnabledScreenAtom',
    default: false,
})

/**
 * Кол-во дней когда станет доступно автоотключение
 */
export const disabledAutoPayoutAvailableSelector = selector({
    key: 'disabledAutoPayoutDaysSelector',
    get: ({get}) => {
        const info = get(AutoPayoutConfigAtom);
        const days = info?.updated_at
            ? moment(info?.updated_at).add(30, 'days').diff(moment(), 'days') + 1
            : 0;
        return days >= 0 ? days : 0;
    }
})

/**
 * В случае если запрошено отключение автоплатежа то подсчет кол-ва дней для автооключения
 */
export const disabledAutoPayoutAfterSelector = selector({
    key: 'disabledAutoPayoutAfterSelector',
    get: ({get}) => {
        const info = get(AutoPayoutConfigAtom);
        const days = info?.disable_at_datetime
            ? moment(info.disable_at_datetime).diff(moment(), 'days') + 1
            : 0;
        return days >= 0 ? days : 0;
    }
})


export const isDisableAllowedSelector = selector({
    key: 'isDisableAllowedSelector',
    get: ({get}) => {
        const info = get(AutoPayoutConfigAtom);
        return !!info?.updated_at && moment(new Date(info.updated_at))
            .add(30, 'days')
            .isBefore(new Date().toString());
    }
})
