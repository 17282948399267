import {AtomEffect} from "recoil";

export function localStorageEffect(key: string) {
    const f:AtomEffect<any>= ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);

        if (savedValue != null) {
            const parseValue = JSON.parse(savedValue);
            if(parseValue?.timestamp){
                setSelf(parseValue.value);
            }else {
                localStorage.removeItem(key);
            }
        }

        onSet(newValue => {
            if (newValue) {
                localStorage.setItem(key, JSON.stringify({
                    timestamp: new Date().toISOString(),
                    value: newValue
                }));
            } else {
                localStorage.removeItem(key);
            }
        });
    };
    return f;
}
