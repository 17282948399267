import {Tier2ContextProvider} from "domain/verification/providers";

import {Navigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";

import {Tier2} from "components/organisms/@verification/tier2";

import {Card} from "../../atoms/card";
import {Screens} from "../../../domain/payout/constants";
import {PendingPayout} from "../../organisms/@payout/pending-payout/pending-payout";
import {SuccessPayout} from "../../organisms/@payout/success-payout/success-payout";
import {PlatformInfo} from "../../organisms/@platforms/info/platform-info";
import {PayoutInfo} from "../../organisms/@payout/payout-info/payout-info";
import {Breadcrumb} from "../../molecules/breadcrumb";
import {Paths} from "../../../core/router";
import {usePayoutFlow} from "../../../domain/payout/providers/payout-flow";
import {Tier3} from "../../organisms/@verification/tier3";
import {isAutoPayoutSelector, PlatformsAtom} from "../../../core/store";
import {Subscribe} from "../../organisms/@platforms/subscribe";
import {ErrorPayout} from "../../organisms/@payout/error-payout/error-payout";
import {Information} from "../../organisms/@payout/information";
import {Tier1} from "../../organisms/@verification/tier1/tier1";
import {VerificationBeforeEnableAuto} from "../../organisms/@verification/verification-before-enable-auto";

import Style from './platforms.module.scss'


export const PlatformsPage = () => {
    const {t} = useTranslation()
    const params = useParams();
    const platforms = useRecoilValue(PlatformsAtom);
    const activePlatform = platforms.find(item => item.key === params.networkId)
    const isAutoPayout = useRecoilValue(isAutoPayoutSelector);

    const {
        screen,
        onBack,
        onFinishVerification,
        isPayoutInProgress,
        resetPayoutFlow,
    } = usePayoutFlow();

    if (!activePlatform) {
        return <Navigate to="/404"/>;
    }
    const getScreen = () => {
        switch (screen) {
            case Screens.INFO:
            default:
                return <Information/>
            case Screens.VERIFICATION_TIER1:
                return <Tier1 onCancel={onBack} onFinish={onFinishVerification}/>
            case Screens.VERIFICATION_TIER2:
                return <Tier2ContextProvider><Tier2 onFinish={onFinishVerification}/></Tier2ContextProvider>
            case Screens.VERIFICATION_TIER3:
                return <Tier3 onBack={resetPayoutFlow}/>
            case Screens.PENDING:
                return <PendingPayout isActivateAuto={isAutoPayout}/>
            case Screens.SUCCESS:
                return <SuccessPayout/>
            case Screens.FAILED:
                return <ErrorPayout/>
        }
    }

    return <Card className={'h-full'}>
        {activePlatform.key === 'admitad' && <VerificationBeforeEnableAuto/>}
            <div className={`${Style.container} h-full`}>
                <div
                    className={`${Style.col1} p-size-5 h-full bg-gradient-to-r from-white/0 from-95% to-gray-100 to-100%`}>
                    <div className={'flex flex-col gap-size-3'}>
                        {isPayoutInProgress
                            ? <PayoutInfo/>
                            : <>
                                <Breadcrumb items={[{
                                    name: t('All networks'),
                                    link: Paths.HOME
                                }, {name: `${t('platfromProfile','Platform profile:')} ${t(activePlatform.title)}`}]}/>
                                <PlatformInfo platform={activePlatform}/>
                            </>
                        }
                    </div>
                </div>
                <div className={'p-size-5'}>
                    {activePlatform.active ? getScreen() : <Subscribe network={params.networkId}/>}
                </div>
            </div>
        </Card>
}
