import {useRecoilState, useRecoilValue} from "recoil";
import {useCallback, useEffect} from "react";
import {useQueryClient} from "@tanstack/react-query";

import {PayoutStepAtom} from "../store/flow";
import {Screens} from "../constants";
import {
    activeAdvanceCalculationSetAtom,
    isAutoPayoutsActiveSelector,
    isAutoPayoutSelector,
    isNoNewPayoutAtom,
    isPayoutInProcessSelector
} from "../../../core/store";
import {useCreatePayout} from "../mutations/create-payout";
import {useAutoPayoutsConfig} from "../../auto-payout/services/auto-payouts";
import {useVerificationService} from "../../verification/services/verification";
import {useCheckPayoutStatus} from "../queries/check-status";
import {QueriesKeys} from "../../../core/constants/queries-keys";

export const usePayoutFlowService = () => {
    const [screen, setScreen] = useRecoilState(PayoutStepAtom);
    const isNoNewPayout = useRecoilValue(isNoNewPayoutAtom);
    const isAutoPayoutEnabled = useRecoilValue(isAutoPayoutsActiveSelector);
    const [isAutoPayout, setAutoPayout] = useRecoilState(isAutoPayoutSelector);
    const isPayoutInProgress = useRecoilValue(isPayoutInProcessSelector);
    const [activeAdvanceCalculationSet, setActiveAdvanceCalculationSet] = useRecoilState(activeAdvanceCalculationSetAtom)

    const client = useQueryClient();

    const {
        create,
        isSuccess,
        isPending,
        isError: isErrorCreatePayout,
        resetCreatePayout,
    } = useCreatePayout();

    const {
        refetchAutoPayoutConfig,
        autoPayoutsConfig
    } = useAutoPayoutsConfig({enabled: screen === Screens.INFO});


    const {
        isNeedVerification,
        refetch: refetchVerificationInfo,
        onStartVerification,
        onFinishVerification,
        isSuccess: isSuccessVerificationState,
        tier: verificationTier,
        setOnFinish,
        onCancelVerification,
    } = useVerificationService();

    const {
        isPaid,
        isFailed,
        resetCheckPayout,
    } = useCheckPayoutStatus(activeAdvanceCalculationSet?.id);


    const onStartVerificationOnly = useCallback(() => {
        if (isNeedVerification) {
            setOnFinish(() => {setScreen(Screens.INFO)});
            onStartVerification();
        }
    }, [isNeedVerification, onStartVerification, setOnFinish, setScreen]);

    useEffect(() => {
        if(isAutoPayoutEnabled) onStartVerificationOnly();
    }, [isAutoPayoutEnabled, onStartVerificationOnly]);

    const onPayout = useCallback(() => {
        if (isNeedVerification) {
            setOnFinish(() => {create(isAutoPayout ? 'auto' : 'manual')});
            onStartVerification();
        } else {
            create(isAutoPayout ? 'auto' : 'manual')
        }
    }, [create, isAutoPayout, isNeedVerification, onStartVerification, setOnFinish])


    const resetPayoutFlow = useCallback(() => {
        onCancelVerification();
        resetCreatePayout();
        resetCheckPayout();
        setActiveAdvanceCalculationSet(null);
        client.refetchQueries({
            queryKey: [QueriesKeys.APPROXIMATE_AMOUNTS]
        });
        refetchAutoPayoutConfig();
        setScreen(Screens.INFO)
    }, [
        client,
        resetCheckPayout,
        onCancelVerification,
        refetchAutoPayoutConfig,
        resetCreatePayout,
        setActiveAdvanceCalculationSet,
        setScreen])

    const onBack = useCallback(() => {
        switch (screen) {
            default:
                onCancelVerification();
                setScreen(Screens.INFO);
        }
    }, [screen, onCancelVerification, setScreen])

    useEffect(() => {
        if (isPending || !!activeAdvanceCalculationSet) setScreen(Screens.PENDING);
        if (isPaid) setScreen(Screens.SUCCESS);
        if (isFailed || isErrorCreatePayout) setScreen(Screens.FAILED)
    }, [
        isAutoPayoutEnabled,
        isPaid,
        isFailed,
        isPending,
        isSuccess,
        screen,
        setScreen,
        activeAdvanceCalculationSet,
        isErrorCreatePayout
    ]);


    return {
        screen,
        onFinishVerification,
        onBack,
        onPayout,
        isPayoutInProgress,
        isAutoPayoutEnabled,
        isAutoPayout,
        setAutoPayout,
        isNoNewPayout,
        autoPayoutsConfig,
        refetchAutoPayoutConfig,
        resetPayoutFlow,
    }
}
