import {AtomEffect} from "recoil";

export function sessionStorageEffect<T = any>(key: string, defaultValue?: T) {
    const f:AtomEffect<any>= ({ setSelf, onSet }) => {
        const savedValue = sessionStorage.getItem(key);
        const saveValue = (value: T) => {
            sessionStorage.setItem(key, JSON.stringify({
                timestamp: new Date().toISOString(),
                value: value
            }));
        }

        if (savedValue != null) {
            const parseValue = JSON.parse(savedValue);
            if(parseValue?.timestamp){
                setSelf(parseValue.value);
            }else {
                sessionStorage.removeItem(key);
            }
        }else if(defaultValue){
            saveValue(defaultValue)
        }

        onSet(newValue => {
            if (newValue !== null) {
                saveValue(newValue)
            } else {
                sessionStorage.removeItem(key);
            }
        });
    };
    return f;
}
