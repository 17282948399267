import { Trans, useTranslation } from "react-i18next"

export const QuestionnaireHeader = () => {
    const {t} = useTranslation()

    return <>
        <div className={'font-heading text-header-04 text-label-secondary font-extrabold'}>
            <Trans>
                <span className={'text-brand-primary'}>Hi</span>, You quickened a lot of payouts via CapyFast. But to let you use the product further, we have to ask you a few questions
            </Trans>
        </div>
        <div className={'text-label-tertiary text-body-02 mt-6'}>
           {t(' We have to ask you a few questions before you may proceed.')}
        </div>
    </>
}
