import {ReactNode, useCallback} from "react";
import classnames from "classnames";
import {ReactComponent as ArrowIcon} from "assets/svg/accordion/arrow.svg";

type ActiveKey = string | number | null | undefined;

type AccordionProps<T> = {
    activeKey?: T,
    items: {
        title: ReactNode,
        content: string,
        key: T,
    }[],
    onChange: (v: T | null) => unknown,
}
export const Accordion = <T extends ActiveKey>({items, activeKey, onChange}: AccordionProps<T>) => {

    const handleClick = useCallback((K: T) => {
        if(activeKey === K) onChange(null);
        else onChange(K);
    }, [activeKey, onChange])

    return <div className={'flex flex-col'}>
        {items.map((children) => {
            return <div key={children.key} className={'border-b '} onClick={() => handleClick(children.key)}>
                <div className={'flex items-center justify-between cursor-pointer py-size-1 hover:bg-background-secondary'}>
                    <div className={'text-body-01 font-medium'}>{children.title}</div>
                    <ArrowIcon className={classnames('transition-all mr-1',{'rotate-180': children.key === activeKey})}/>
                </div>
                <div className={classnames('overflow-hidden', {
                    'max-h-0': children.key !== activeKey,
                    'max-h-[10000px]': children.key === activeKey  // Замените 1000px на максимальную высоту, которую может достичь ваш контент
                })}>
                    <div className={'py-size-1 prose'} dangerouslySetInnerHTML={{__html: children.content}}/>
                </div>
            </div>
        })}
    </div>
}
