import {useRecoilState} from "recoil";

import {Tier1StateAtom, Tier1States} from "../../../../core/store";
import {VerificationInfo} from "../../@payout/verification-info/verification-info";
import {CreatePassport} from "../../@payout/create-passport/create-passport";

import {WebSdk} from "./web-sdk";
import {SuccessTier1} from "./success";
import {FailedTier1} from "./failed";
import {NeedActionTier1} from "./need_action";

type Tier1Props = {
    onCancel: Parameters<typeof VerificationInfo>[0]['onCancel'],
    onFinish: Parameters<typeof WebSdk>[0]['onFinish']
}

export const Tier1 = ({onFinish, onCancel}: Tier1Props) => {
    const [state, setState] = useRecoilState(Tier1StateAtom)

    const startSDK = () => setState(Tier1States.SDK);
    const selectPersonality = () => setState(Tier1States.PASSPORT);

    switch(state){
        case Tier1States.PASSPORT:
            return <CreatePassport onSuccess={startSDK} onBack={onCancel}/>;
        case Tier1States.WELCOME:
            return <CreatePassport onSuccess={startSDK} onBack={onCancel}/>;
            // return <VerificationInfo onSuccess={startSDK} onCancel={onCancel}/>
        case Tier1States.SDK:
            return <WebSdk onFinish={onFinish} onBack={selectPersonality}/>
        case Tier1States.SUCCESS:
            return <SuccessTier1/>
        case Tier1States.FAILED:
            return <FailedTier1/>
        case Tier1States.NEED_ACTION:
            return <NeedActionTier1/>
    }
}
