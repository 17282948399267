"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticSchema = void 0;
const zod_1 = require("zod");
exports.StatisticSchema = zod_1.z.object({
    total_sum: zod_1.z.string(),
    saved_with_auto: zod_1.z.string(),
    saved_time: zod_1.z.number(),
    saved_time_with_auto: zod_1.z.number()
});
