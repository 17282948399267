import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import React, {ReactNode} from "react";
import {AnimatePresence, motion} from "framer-motion";

import {NotFoundPage} from "components/@pages/not-found";

import {Layout} from "../../components/molecules/layout";
import {HomePage} from "../../components/@pages/home";
import {History} from "../../components/@pages/history/history";
import {PlatformsPage} from "../../components/@pages/platforms";
import {OnboardingRouter} from "../../domain/user/routers/onboarding-router";
import {Support} from "../../components/@pages/support";
import {I18NProvider} from "../../domain/i18n/providers/i18n";

import {Paths} from "./paths";
import {PrivateRouter} from "./private-router";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <RoutesWithAnimation/>
        </BrowserRouter>
    );
}

const RouteAnimate = ({children}: { children: ReactNode }) => {
    return <motion.div transition={{
        ease: "linear",
        duration: 0.1,
    }}
                       className={'min-h-full grow flex flex-col'}
                       initial={{opacity: 0.5, scale: 0.99}}
                       animate={{opacity: 1, scale: 1}}
                       exit={{opacity: 0, scale: 0.99}}>{children}</motion.div>
}

const RoutesWithAnimation = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode={'wait'}>
            <Routes key={location.key}>
                <Route element={<I18NProvider><Layout/></I18NProvider>}>
                    <Route element={<PrivateRouter/>}>
                        <Route element={<OnboardingRouter/>}>
                            <Route path={Paths.HOME}
                                   element={<RouteAnimate><HomePage/></RouteAnimate>}>
                            </Route>
                            <Route path={`${Paths.PLATFORMS}:networkId`}
                                   element={<RouteAnimate><PlatformsPage/></RouteAnimate>}>
                            </Route>
                            <Route path={Paths.HISTORY}
                                   element={<RouteAnimate><History/></RouteAnimate>}>
                            </Route>
                        </Route>
                        <Route path={Paths.SUPPORT} element={<RouteAnimate><Support/></RouteAnimate>}></Route>
                    </Route>
                    <Route path={'*'} element={<NotFoundPage/>}/>
                </Route>
            </Routes>
        </AnimatePresence>
    );
}
