import {useRecoilValue} from "recoil";
import {Outlet} from "react-router-dom";
import {instancePayoutClientTypeAtom, isApiReadySelector, isAuthSelector, isNeedSignDocsAtom} from "core/store";
import {Loader} from "@design-system/ui-kit";
import React from "react";

import {Onboarding} from "components/@pages/onboarding";
import {Card} from "components/atoms/card";

import {useUser} from "../providers/user";
import {
    InstantPayoutClient
} from "../../../components/organisms/@onboarding/instant-payout-client/instant-payout-client";


export const OnboardingRouter = () => {
    const isAuth = useRecoilValue(isAuthSelector)
    const isReady = useRecoilValue(isApiReadySelector);
    const isNeedShowOnboarding = useRecoilValue(isNeedSignDocsAtom);
    const isInstancePayoutClient = useRecoilValue(instancePayoutClientTypeAtom);
    const {isUserLoaded} = useUser();



    if(!isUserLoaded || !isAuth || !isReady) return <Card className={'overflow-hidden min-h-[calc(100dvh-68px-100px)] flex flex-col gap-size-3 items-center justify-center'}>
        <Loader/>
        Your profile loading...
    </Card>

    if(isNeedShowOnboarding && !isInstancePayoutClient){
        return <Onboarding/>
    }

    return <>
        <Outlet/>
        {isInstancePayoutClient && isNeedShowOnboarding && <InstantPayoutClient/>}
    </>

}
