import {getCommonApiClient} from "../client";
import {AutoPayoutsResponseSchema} from "@capyfast/contracts";

export function getAutoPayoutsConfig(){
    return getCommonApiClient().get<AutoPayoutsResponseSchema>('/api/internal/v1/finance/auto-payouts/configs/')
}
export function enableAutoPayouts () {
    return getCommonApiClient().post<any>('/api/internal/v1/finance/auto-payouts/enable/')
}
export function disableAutoPayouts () {
    return getCommonApiClient().post<any>('/api/internal/v1/finance/auto-payouts/disable/')
}
