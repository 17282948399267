import {getCommonApiClient} from "../client";

export function saveQuestionnaire(data: {
    user_verification: number | string
    traffic_type: string,
    other_networks: string,
    admitad_share: string
}) {
    return getCommonApiClient().post('/api/internal/v1/passport/questionnaire/', data)
}
