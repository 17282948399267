import {HistoryItemSchema, PayoutStatuses} from "@capyfast/contracts";
import moment from "moment/moment";
import {formatAmount} from "@capyfast/utils";
import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {ReactComponent as DownloadIcon} from "assets/svg/interface-icons/download.svg";
import { useTranslation } from 'react-i18next';

import {Modal} from "../../../molecules/modal";
import {getStatusVariant, typeKeys} from "../../../../domain/history/constants";
import {StatusLabel} from "../../../atoms/status-label";


type DetailsModalProps = {
    item?: HistoryItemSchema | null,
    onClose?: () => unknown
}
export const DetailsModal = ({item, onClose}: DetailsModalProps) => {
    const {t} = useTranslation()

    if(!item) return null;

    return <Modal isShow={!!item} onClose={onClose}>
        <div className={'space-y-8'}>
            <div className={'text-header-05 font-heading font-bold text-label-secondary'}>{t('Detalisation')}</div>
            <div className={'space-y-2'}>
                <div className={'flex justify-between border-b pb-2'}>
                    <div className={'text-label-tertiary'}>{t('Transaction ID')}</div>
                    <div className={'text-label-secondary'}>{item.id}</div>
                </div>
                <div className={'flex justify-between border-b pb-2'}>
                    <div className={'text-label-tertiary'}>{t('Platform')}</div>
                    <div className={'text-label-secondary underline'}>{t('Admitad Affiliate Network')}</div>
                </div>
                <div className={'flex justify-between border-b pb-2'}>
                    <div className={'text-label-tertiary'}>{t('Type')}</div>
                    <div className={'text-label-secondary'}>{typeKeys[item.type]}</div>
                </div>
                <div className={'flex justify-between border-b pb-2'}>
                    <div className={'text-label-tertiary'}>{t('Status')}</div>
                    <div className={'text-label-secondary'}><StatusLabel variant={getStatusVariant(item.status)}>{PayoutStatuses[item.status]}</StatusLabel></div>
                </div>
                <div className={'flex justify-between'}>
                    <div className={'text-label-tertiary'}>{t('Date of operation')}</div>
                    <div className={'text-label-secondary'}>{moment(item?.created_at).format('DD.MM.YYYY')}</div>
                </div>
            </div>
            <div className={'bg-background-tertiary rounded p-5'}>
                <div className={'grid grid-cols-2 gap-y-1'}>
                    <div className={'flex items-center'}>
                        {t('Amount of rewards')}
                        {/*<Help content={'test'} className={'ml-2 text-interface-quaternary'}/>*/}
                    </div>
                    <div className={'flex items-center'}>
                        {t('Total amount')}
                        {/*<Help content={'test'} className={'ml-2 text-interface-quaternary'}/>*/}
                    </div>
                    <div className={'text-body-01 font-medium'}>{formatAmount(item.original_amount)} {item.currency_code}</div>
                    <div className={'text-body-01 font-medium'}>{formatAmount(item.final_amount)} {item.currency_code}</div>
                </div>
            </div>
            {!!item?.download_link && <div>
                <Button
                    iconStart={() => <DownloadIcon/>}
                    onClick={() => window.open(item?.download_link, 'blank')}
                    theme={ButtonThemes.interfaceOnsurface}
                    size={ButtonSizes.xl}>
                    {t('Download detalization')}
                </Button>
            </div>}
        </div>
    </Modal>
}
