import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";

const isShowDefault = !window.sessionStorage.getItem('hide-bata-notice');

export const Beta = () => {
   const [isShow, setIsShow] = useState<boolean>(isShowDefault)

    const {t} = useTranslation();

    const onClose = () => {
       setIsShow(false);
       window.sessionStorage.setItem('hide-bata-notice', 'true')
    }

    if(!isShow) return null;
    return <div
        className={'relative px-6 py-size-1 mb-size-1 bg-background-sixth text-label-onsurface flex justify-center items-center gap-4'}>
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12.7482 0L13.9758 0.154038C23.1177 1.65935 27.2504 12.8371 21.0367 19.8953C14.298 27.5508 1.85997 23.8827 0.163098 13.9773L0.00917863 12.7488C0.030452 12.2529 -0.019603 11.7432 0.00917863 11.2491C0.357061 5.29301 5.29812 0.34815 11.2497 0H12.7482ZM11.3804 1.42015C3.11948 1.89103 -1.45243 11.3719 3.38977 18.1539C7.59753 24.0474 16.3634 24.0649 20.5881 18.1808C25.7894 10.936 20.2277 0.915459 11.3804 1.42015Z"
                    fill="#EAFD14"/>
                <path
                    d="M10.8279 16.1237C10.8911 17.547 9.8312 18.9077 8.44092 19.1951C8.08366 19.269 7.61877 19.3491 7.31719 19.098C6.88233 18.7367 7.08756 17.9778 7.63003 17.8682C7.75142 17.8438 7.8778 17.8758 7.9898 17.8532C8.75439 17.6979 9.25682 17.2433 9.41074 16.4631C9.58531 13.6366 9.21991 10.6047 9.42075 7.80258C9.58844 5.46885 12.0899 4.02178 14.1941 5.0919C16.0293 6.02489 16.5342 8.42061 15.286 10.0549C16.0149 10.6423 16.5818 11.3442 16.8089 12.2709C17.5829 15.4337 14.2185 18.0292 11.3585 16.46C11.2878 16.4212 10.8536 16.0949 10.8279 16.1244V16.1237ZM12.4566 6.15326C11.5781 6.25532 10.8905 7.023 10.826 7.89588C10.9449 9.66669 10.6728 11.6329 10.8286 13.3805C10.9925 15.2233 13.1943 16.1713 14.644 14.9973C15.8422 14.0267 15.7878 12.1645 14.5339 11.2659C13.997 10.8815 14.0846 11.0643 13.5541 11.1877C13.2074 11.2684 12.8483 11.3755 12.5373 11.1551C12.1719 10.8952 12.1206 10.3949 12.4441 10.0775C12.6831 9.84264 13.2568 9.84765 13.6385 9.63225C15.3898 8.64416 14.5045 5.91531 12.4566 6.15263V6.15326Z"
                    fill="#EAFD14"/>
            </svg>
        </div>
        <div>
            <Trans i18nKey={'beta'}
                components={[<a className={'text-interface-lime underline'}
                                href={'https://forms.gle/y2qQgL4euCRadtW99'}
                                target={'_blank'}
                                rel="noreferrer"/>]}>
                CapyFast is currently in Beta. If you encounter any issues or bugs,{' '}
                please let us know by filling out our feedback form. Your feedback helps us improve!
            </Trans>
        </div>
        {/*<div className={'w-10'}></div>*/}
        {/*<div onClick={onClose}*/}
        {/*     className={'absolute w-10 right-2 top-2 cursor-pointer'}>*/}
        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">*/}
        {/*        <path d="M10.6666 10.6667L21.3333 21.3334" stroke="white" strokeWidth="1.5" strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"/>*/}
        {/*        <path d="M21.3333 10.6667L10.6666 21.3334" stroke="white" strokeWidth="1.5" strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"/>*/}
        {/*    </svg>*/}
        {/*</div>*/}
    </div>
}
