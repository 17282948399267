import {atom} from "recoil";
import {NavigationItems} from "core/icons/navigation";

import {AsideItemsType} from "components/organisms/@aside";

import {Paths} from "../router";
import {ENV} from "../environment";

export const mainNavigationItemsAtom = atom<AsideItemsType>({
    key: 'mainNavigationItemsAtom',
    default: [
        {key: 'home', title: 'Home', icon: NavigationItems.Home, isActive: false, link: Paths.HOME},
        {key: 'history', title: 'History', icon: NavigationItems.Payment, isActive: false, link: Paths.HISTORY}
    ],
})

export const secondaryNavigationItemsAtom = atom<AsideItemsType>({
    key: 'secondaryNavigationItemsAtom',
    default: [
        {
            key: 'wallet',
            title: 'Wallet',
            icon: NavigationItems.Wallet,
            isActive: false,
            link: ENV.WALLET_LINK,
            target: '_blank'
        },
    ],
});

export const helpNavigationItemsAtom = atom<AsideItemsType>({
    key: 'helpNavigationItemsAtom',
    default: [
        {key: 'help', title: null, icon: NavigationItems.HelpIcon, isActive: false, link: Paths.SUPPORT},
    ]
})

