import {ReactComponent as Icon} from "assets/svg/interface-icons/warning.svg";
import {useTranslation} from "react-i18next";

export const NeedActionTier1 = () => {
    const {t} = useTranslation()

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}><Icon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>
            {t(' Oops, something went wrong')}
        </div>
        <div className={'text-center text-body-01 text-label-tertiary max-w-[20.5rem]'}>
            {t('You haven’t provided all the necessary info. Please restart and complete all the steps.')}
        </div>
    </div>
}

