import {Outlet} from "react-router-dom";
import {useRecoilValue} from "recoil";
import classnames from "classnames";

import {Aside} from "components/organisms/@aside";
import {Topmenu} from "components//organisms/@topmenu";
import {Footer} from "components//organisms/@footer";

import {hideLayoutNavigationSelector} from "../../../core/store/layout.store";
import {Beta} from "../beta";
import {WhatsUpButton} from "../../atoms/whats-up-button/whats-up-button";

import Style from './layout.module.scss'

export const Layout = () => {
    const hideNavigation = useRecoilValue(hideLayoutNavigationSelector);

    return <>
        <Beta/>
        <div className={Style.layoutContainer}>
            <div className={classnames(Style.layout, {[Style.noNavigation]: hideNavigation})}>
                <div className={Style.topmenu}>
                    <Topmenu/>
                </div>
                {!hideNavigation &&
                    <div className={Style.aside}>
                        <Aside/>
                    </div>}
                <div className={Style.content}>
                    <Outlet/>
                </div>
                <div className={Style.footer}>
                    <Footer/>
                </div>
            </div>
        </div>
        <WhatsUpButton/>
    </>
}
