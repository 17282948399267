import {Input} from "@design-system/ui-kit";
import {useLayoutEffect} from "react";
import { useTranslation } from "react-i18next";

import {useTier2} from "../../../../../domain/verification/providers";
import {VerificationNavigation} from "../../navigation/navigation";
import {q3ValueItemSchema} from "../../../../../core/store";

import {QuestionnaireHeader} from "./header";

export const VerificationTier2Q3 = () => {
    const {t} = useTranslation()

    const {
        q2Values,
        networksVariants,
        onNext,
        onBack,
        isQ3Verified,
        q3Values,
        setQ3Values
    } = useTier2();

    const isValidValue = (v: string) => {
        try {
            q3ValueItemSchema.parse(v)
            return true;
        } catch (e) {
            return false;
        }
    }

    useLayoutEffect(() => {
        setQ3Values(() => Object
            .keys(q2Values)
            .reduce((obj, key) => {
                return q2Values[key]
                    ? {...obj, [key]: q3Values[key] ?? ''}
                    : obj;
            }, {}))
    }, []);

    return <>
        <div>
            <QuestionnaireHeader/>
        </div>

        <div>
            <h2 className={'text-header-06 font-heading font-bold text-label-secondary mb-6'}>
                {t('3. What percentage do these affiliate networks have in your business?')}
            </h2>
            <div className={'flex flex-col gap-size-0.5'}>
                {Object.keys(q3Values).map((key, index) => {
                    return <div
                        key={key}
                        className={'grid grid-cols-[25%,30%] items-center gap-x-size-0.5 gap-y-6'}>
                        <div className={'text-body-02 text-label-tertiary'}>{index + 1}. {networksVariants[key]}</div>
                        <div>
                            <Input value={q3Values[key]}
                                   type='number'
                                   placeholder={'%'}
                                   nativePlaceholder

                                   error={!!q3Values[key] && !isValidValue(q3Values[key])}
                                   onChange={(v) => {
                                       setQ3Values((prev) => {
                                           return {...prev, [key]: v}
                                       })
                                   }}/>
                            {!!q3Values[key] && !isValidValue(q3Values[key]) &&
                                <div className={'text-label-danger text-body-04'}>
                                   {t(' Must be a number between 0 and 100')}
                                </div>}
                        </div>
                    </div>
                })}
            </div>
        </div>
        <VerificationNavigation onNext={onNext}
                                onBack={onBack}
                                isDisabledNext={!isQ3Verified}/>
    </>
}
