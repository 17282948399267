import {AxiosInstance} from "axios";

export function configureCommonApiClient(instance: AxiosInstance) {
  window.apiClient = instance;
}

export function getCommonApiClient(): AxiosInstance {
  if (!window.apiClient) {
    throw new Error(
      "API client is not configured. Please call configureApiClient first.",
    );
  }
  return window.apiClient;
}
