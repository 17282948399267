import {ReactComponent as Avatar} from "assets/svg/profile/avatar.svg";
import {truncate} from "@capyfast/utils";

import {useUser} from "../../../domain/user/providers/user";

export const Profile = () => {
    const {payload}= useUser();

    return <div className={'flex flex-nowrap gap-2 items-center'}>
        <span>{truncate(payload.email ?? '', 25)}</span>
        <div className={'bg-interface-primary rounded-full aspect-square flex justify-center items-center'}>
            <Avatar/>
        </div>
    </div>
}
